const AdicionarCliente = () => {
    return (
      <div className="flex-1 p-8 bg-gray-100">
        <h1 className="text-2xl font-bold mb-4">Adicionar Cliente</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          <div className="bg-white p-4 rounded shadow">
            <div>
                <span>Aqui vai o formulario para adicionar os clientes</span>
            </div>

          </div>
          
        </div>
      </div>
    );
  };
  
  export default AdicionarCliente;