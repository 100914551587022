import React, { useEffect, useState } from "react";
import api from "../../config/apiAdm";
import { AiFillLike } from "react-icons/ai";
import { IoIosDocument } from "react-icons/io";
import { FaEdit } from "react-icons/fa";
import { RiToolsLine } from "react-icons/ri";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import { useNavigate } from 'react-router-dom';



const VerPropostas = () => {
  const navigate = useNavigate();
  const [propostas, setPropostas] = useState([]);
  const [filtro, setFiltro] = useState("");
  const [propostasFiltradas, setPropostasFiltradas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [selectedProposta, setSelectedProposta] = useState(null);
  const [visualizarTodas, setVisualizarTodas] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [propostaEditando, setPropostaEditando] = useState(null);

  const openEditModal = (proposta) => {
    setPropostaEditando(proposta);
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setPropostaEditando(null);
    setIsEditModalOpen(false);
  };

  const handleEditarProposta = async () => {
    const { id } = propostaEditando;

    try {
      const response = await api.put(`/editarProposta/${id}`, {
        RazaoSocial: propostaEditando["Razão Social"],
        Valor: propostaEditando.Valor,
        Entrada: propostaEditando.Entrada,
        FormaPagamento: propostaEditando["Forma de Pagamento"],
        valorParcela: propostaEditando.valorParcela,
        Conta: propostaEditando.Conta,
      });

      if (!response.data.erro) {
        alert("Proposta atualizada com sucesso!");
        fetchPropostas(); // Recarrega a lista de propostas
        closeEditModal(); // Fecha o modal
      } else {
        alert("Erro ao atualizar proposta: " + response.data.mensagem);
      }
    } catch (error) {
      console.error("Erro ao atualizar proposta:", error);
      alert("Erro ao atualizar proposta.");
    }
  };



  // Buscar propostas do backend
  const fetchPropostas = async () => {
    try {
      setLoading(true);
      const response = await api.get("/verPropostas");
      const todasPropostas = response.data.dados;

      // Exibir inicialmente as 30 propostas mais recentes
      setPropostasFiltradas(
        todasPropostas
          .sort((a, b) => b.id - a.id) // Ordena pelo ID em ordem decrescente
          .slice(0, 30) // Seleciona os 30 primeiros após ordenação
      );
      setPropostas(todasPropostas);
      setVisualizarTodas(false)
    } catch (error) {
      console.error("Erro ao buscar propostas:", error);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    fetchPropostas();
  }, []);

  const handleVisualizarPropostas = (todas) => {
    setVisualizarTodas(todas);
    setPropostasFiltradas(
      todas ? propostas : propostas.slice(-30).reverse()
    );
  };

  // Filtrar propostas
  const handleFiltroChange = (e) => {
    const textoFiltro = e.target.value.toLowerCase();
    setFiltro(textoFiltro);
    setPropostasFiltradas(
      propostas.filter((proposta) =>
        proposta["Razão Social"]?.toLowerCase().includes(textoFiltro)
      )
    );
  };

  // Abrir modal de confirmação
  const openConfirmModal = (proposta) => {
    setSelectedProposta(proposta);
    setIsConfirmModalOpen(true);
  };

  // Fechar modal de confirmação
  const closeConfirmModal = () => {
    setSelectedProposta(null);
    setIsConfirmModalOpen(false);
  };

  // Confirmar ação de atualizar status e inserir na tabela
  const confirmHandleCliente = async () => {
    if (selectedProposta && selectedProposta.Aceite !== "Y") {
      try {
        // Atualizar status da proposta
        const response = await api.put(
          `/atualizarProposta/${selectedProposta.id}`,
          { Aceite: "Y" }
        );

        if (!response.data.erro) {
          alert("Proposta marcada como Cliente com sucesso!");

          // Processar entrada e parcelas
          const entrada = selectedProposta.Entrada;
          const valorParcela = selectedProposta.valorParcela;
          const parcelas = selectedProposta['Forma de Pagamento'];
          const idProposta = selectedProposta.id_Proposta;
          const razaoSocial = selectedProposta['Razão Social'];
          const conta = selectedProposta.Conta;

          // Construir os registros para entrada e parcelas
          const registros = [
            {
              id_Proposta: idProposta,
              razao_Social: razaoSocial,
              valor_pagar: entrada,
              data_prevista: new Date(), // Data atual para entrada
              pagamento_realizado: 'Nao',
              conta: conta,
            },
          ];

          for (let i = 0; i < parcelas; i++) {
            registros.push({
              id_Proposta: idProposta,
              razao_Social: razaoSocial,
              valor_pagar: valorParcela,
              data_prevista: new Date(new Date().setMonth(new Date().getMonth() + (i + 1))), // Incrementar meses
              pagamento_realizado: 'Nao',
              conta: conta,
            });
          }
          // Inserir dados na tabela 'Caixa'
          const caixaData = {
            id_Proposta: selectedProposta.id_Proposta,
            data_inicio: new Date(), // Usar data atual
            razao_Social: selectedProposta['Razão Social'],
            valor: selectedProposta.Valor,
            entrada: selectedProposta.Entrada,
            parcelas: selectedProposta['Forma de Pagamento'],
            valorParcela: selectedProposta.valorParcela,
            conta: selectedProposta.Conta,
          };

          const insertRes = await api.post("/inserirCaixa", caixaData);

          if (!insertRes.data.erro) {
            alert("Dados salvos no caixa com sucesso!");
          } else {
            alert("Erro ao inserir dados na tabela.");
          }



          // Enviar os registros para a API
          const insertResponse = await api.post("/criareventocaixa", registros);

          if (!insertResponse.data.erro) {
            alert("Dados do fluxo de caixa, salvos com sucesso!");
          } else {
            alert("Erro ao inserir dados na tabela.");
          }

          fetchPropostas(); // Recarregar propostas
        } else {
          alert("Erro ao atualizar proposta.");
        }
      } catch (error) {
        console.error("Erro ao atualizar proposta ou inserir dados:", error);
        alert("Erro ao processar a solicitação.");
      } finally {
        closeConfirmModal();
      }

    }
  };


  const VerPropostas = (id_Proposta) => {
    console.log('o id da proposta é: ' + id_Proposta);
    localStorage.setItem('PesqProposta', id_Proposta)
    navigate(`/AreaAdm/Propostas/PropostaFeita/${id_Proposta}`);
  }
  const VerFatura = (id_Proposta) => {
    console.log('o id da proposta é: ' + id_Proposta);
    localStorage.setItem('PesqProposta', id_Proposta)
    navigate(`/AreaAdm/Propostas/FazerFatura/${id_Proposta}`);
  }
  return (
    <div className="flex-1 p-8 bg-gray-100">
      <h1 className="text-2xl font-bold mb-6">Visualizar Propostas</h1>

      <input
        type="text"
        placeholder="Filtrar por Razão Social"
        value={filtro}
        onChange={handleFiltroChange}
        className="mb-4 p-2 border rounded w-full md:w-1/2 focus:outline-none focus:ring-2 focus:ring-blue-500"
      />
      <div className="mb-4 flex space-x-4">
        <button
          className={`px-4 py-2 rounded ${!visualizarTodas ? "bg-blue-500 text-white" : "bg-gray-300"}`}
          onClick={() => fetchPropostas(false)}
        >
          Últimas 30 Propostas
        </button>
        <button
          className={`px-4 py-2 rounded ${visualizarTodas ? "bg-blue-500 text-white" : "bg-gray-300"}`}
          onClick={() => handleVisualizarPropostas(true)}
        >
          Todas as Propostas
        </button>
      </div>

      {loading ? (
        <div className="flex justify-center items-center h-64">
          <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-16 w-16 animate-spin"></div>
          <p className="ml-3 text-gray-600 text-lg">Carregando...</p>
        </div>
      ) : (
        <table className="min-w-full bg-white border rounded shadow">
          <thead>
            <tr className="bg-gray-100 text-center text-sm font-semibold text-gray-700">
              <th className="px-4 py-3 border-b">ID</th>
              <th className="px-4 py-3 border-b">Data</th>
              <th className="px-4 py-3 border-b">Razão Social</th>
              <th className="px-4 py-3 border-b">Valor</th>
              <th className="px-4 py-3 border-b">Ver Proposta</th>
              <th className="px-4 py-3 border-b">Fazer Fatura</th>
              <th className="px-4 py-3 border-b">Edições</th>

              <th className="px-4 py-3 border-b text-center">Aprovar</th>
            </tr>
          </thead>
          <tbody>
            {propostasFiltradas.map((proposta) => (
              <tr key={proposta.id} className="hover:bg-gray-100 text-justify">
                <td className="px-4 py-3 border-b">{proposta.id_Proposta}</td>
                <td className="px-4 py-3 border-b">
                  {new Date(proposta.Data).toLocaleDateString("pt-BR")}
                </td>
                <td className="px-4 py-3 border-b text">{proposta["Razão Social"]}
                  <br /><small>{proposta.Documento}: {proposta.Numero} | {proposta.Cidade + "-" + proposta.UF} -
                    <a
                      target="_blank"
                      className="bg-emerald-700 hover:bg-emerald-950 text-white pl-1 pr-1 ml-2 rounded-md"
                      href={`https://api.whatsapp.com/send?phone=${proposta.Telefone ? proposta.Telefone.replace(/\D/g, '') : ''}`}
                    >
                      whatsapp
                    </a>
                    <br/>
                    Autor: {proposta.Autor}</small>
                </td>
                <td className="px-4 py-3 border-b">
                  {Number(proposta.Valor).toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  })}
                  <br /><small> {Number(proposta.Entrada).toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  })} +{proposta["Forma de Pagamento"]} x de R$ {Number(
                    proposta.valorParcela
                  ).toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  })} </small>
                </td>

                <td className="px-4 py-3 border-b">
                  <button
                    className="bg-sky-600 text-white rounded-lg shadow-md text-xl hover:bg-white hover:text-sky-600 p-2"
                    onClick={() => VerPropostas(proposta.id_Proposta)}>
                    <IoIosDocument />
                  </button>
                </td>
                <td className="px-4 py-3 border-b">
                  <button className="bg-emerald-600 text-white rounded-lg shadow-md text-xl hover:bg-white hover:text-emerald-600 p-2"
                    onClick={() => VerFatura(proposta.id_Proposta)}><LiaFileInvoiceDollarSolid /></button>
                </td>
                <td className="px-4 py-3 border-b">
                  <div className="flex justify-center space-x-6">
                    {/* Botão para editar proposta */}
                    <div className="flex flex-col items-center">
                      <button
                        className="bg-orange-600 text-white rounded-lg shadow-md text-xl hover:bg-white hover:text-orange-600 p-2"
                        onClick={() => openEditModal(proposta)}
                      >
                        <FaEdit />
                      </button>
                    </div>

                    {/* Botão para editar serviços */}
                    <div className="flex flex-col items-center">
                      <button className="bg-amber-400 text-white rounded-lg shadow-md text-xl hover:bg-white hover:text-amber-400 p-2">
                        <RiToolsLine />
                      </button>
                      <small className="mt-1 text-center">Editar Serviços</small>
                    </div>
                  </div>
                </td>

                <td className="px-4 py-3 border-b">
                  {proposta.Aceite === "Y" ? (
                    <AiFillLike className="text-green-400 text-2xl" />
                  ) : (
                    <button onClick={() => openConfirmModal(proposta)}>
                      <AiFillLike className="text-gray-400 hover:text-blue-500 text-2xl" />
                    </button>)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {isEditModalOpen && propostaEditando && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-8 rounded shadow-lg w-1/3">
            <h2 className="text-xl font-semibold mb-4">Editar Proposta</h2>
            <form
              onSubmit={async (e) => {
                e.preventDefault();
                await handleEditarProposta();
              }}
            >
              <label className="block mb-2">
                Razão Social:
                <input
                  type="text"
                  defaultValue={propostaEditando["Razão Social"]}
                  onChange={(e) => setPropostaEditando({ ...propostaEditando, "Razão Social": e.target.value })}
                  className="w-full p-2 border rounded"
                />
              </label>
              <label className="block mb-2">
                Valor:
                <input
                  type="number"
                  defaultValue={propostaEditando.Valor}
                  onChange={(e) => setPropostaEditando({ ...propostaEditando, Valor: parseFloat(e.target.value) })}
                  className="w-full p-2 border rounded"
                />
              </label>
              <label className="block mb-2">
                Entrada:
                <input
                  type="number"
                  defaultValue={propostaEditando.Entrada}
                  onChange={(e) => setPropostaEditando({ ...propostaEditando, Entrada: parseFloat(e.target.value) })}
                  className="w-full p-2 border rounded"
                />
              </label>
              <label className="block mb-2">
                Forma de Pagamento:
                <input
                  type="number"
                  defaultValue={propostaEditando["Forma de Pagamento"]}
                  onChange={(e) => setPropostaEditando({ ...propostaEditando, "Forma de Pagamento": parseInt(e.target.value) })}
                  className="w-full p-2 border rounded"
                />
              </label>
              <label className="block mb-2">
                Valor da Parcela:
                <input
                  type="number"
                  defaultValue={propostaEditando.valorParcela}
                  onChange={(e) => setPropostaEditando({ ...propostaEditando, valorParcela: parseFloat(e.target.value) })}
                  className="w-full p-2 border rounded"
                />
              </label>
              <label className="block mb-2">
                Conta:
                <select
                  defaultValue={propostaEditando.Conta}
                  onChange={(e) => setPropostaEditando({ ...propostaEditando, Conta: e.target.value })}
                  className="w-full p-2 border rounded"
                >
                  <option value="inbravisa">Inbravisa</option>
                  <option value="rui">Rui</option>
                </select>
              </label>
              <div className="mt-4 flex justify-end space-x-4">
                <button
                  type="button"
                  className="bg-gray-300 px-4 py-2 rounded"
                  onClick={closeEditModal}
                >
                  Cancelar
                </button>
                <button
                  type="submit"
                  className="bg-blue-500 text-white px-4 py-2 rounded"
                >
                  Salvar
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      {/* Modal de confirmação */}
      {isConfirmModalOpen && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-8 rounded shadow-lg w-1/3">
            <h2 className="text-xl font-semibold mb-4">Confirmar Ação</h2>
            <p>Deseja realmente marcar esta proposta como Cliente?</p>
            <div className="mt-4 flex justify-end space-x-4">
              <button
                className="bg-gray-300 px-4 py-2 rounded"
                onClick={closeConfirmModal}
              >
                Cancelar
              </button>
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded"
                onClick={confirmHandleCliente}
              >
                Confirmar
              </button>
            </div>
          </div>
        </div>
      )}
    </div>

  );
};

export default VerPropostas;
