import CompKanban from "../CompKanban";

const Dashboards = () => {
    return (
      <div className="flex-1 p-8 bg-gray-100">
        <h1 className="text-2xl font-bold mb-4">Bem-vindo ao Dashboard</h1>        
        <CompKanban/>
      </div>
    );
  };
  
  export default Dashboards;