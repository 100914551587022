import React from "react";
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from "./Routes";
import history from "./services/history";
import { AuthProvider } from "./Context/authContext";



function App() {
    


    return (
        <div>
            <AuthProvider>
                <Router history={history}>
                    <Routes />
                </Router>
            </AuthProvider>
        </div>
    )

}

export default App;





















/*
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {AuthProvider} from './Context/authContext'



import { Home} from './Pages/Home';
import {Protocolo} from './Pages/Protocolo';
import { Comprar } from "./Pages/Comprar";
import { Login } from "./Pages/Login";
import { Area_Aluno } from "./Pages/Area_Aluno";
import { Assistir } from "./Pages/Assistir";

function App() {
    return (
    <div>
        <AuthProvider>
            <Router>
                <Routes>
                    <Route 
                        path="/"
                        element={<Home />}
                    />
                    <Route 
                        path="/Protocolo/:id"
                        element={<Protocolo />}
                    />
                    <Route 
                        path="/Comprar/:id"
                        element={<Comprar />}
                    />
                    <Route 
                        path="/Login/"
                        element={<Login />}
                    />
                    <Route 
                        path="/Area_Aluno/"
                        element={<Area_Aluno />}
                    />
                    <Route 
                        path="/Assistir/:idProtocolo"
                        element={<Assistir />}
                    />

                </Routes>            
            </Router> 
        </AuthProvider>
    </div>
    );

}

export default App;
*/