import React from "react";
import moment from "moment"; // Para obter o ano no formato desejado
import { useState, useEffect } from "react";
import { BiSave } from "react-icons/bi";
import InputMask from "react-input-mask";
import { NumericFormat } from "react-number-format";
import api from '../../config/apiAdm';
import { useNavigate} from 'react-router-dom';

// Token Receita WS: 90b07e8c2437795009565ac0bb1c26b19d610c29756856d1478664f36be53ff5

const AdicionarConsulta = () => {
  const navigate = useNavigate();
  const [ultimoId, setUltimoId] = useState(null); // Último ID da API
  const [novoIdProposta, setNovoIdProposta] = useState(""); // Novo ID gerado
  const [tipos, setTipos] = useState([]);
  const [selectedTipo, setSelectedTipo] = useState('');
  const [valor, setValor] = useState("");
  const [entrada, setEntrada] = useState("");
  const [parcelas, setParcelas] = useState("");
  const [valorParcela, setValorparc] = useState("");
  const [nomeContato, setNomeContato] = useState('');
  const [email, setEmail] = useState('');
  const [conta, setConta] = useState('');



  const handleParcela = (e) => {
    const nparcela = e.target.value;
    setParcelas(nparcela)
    console.log('numero de parcelas' + nparcela)
    localStorage.setItem('NParcelas', nparcela)
    //CALCULAR A PARCELA
    const calc1 = parseFloat(localStorage.getItem('ValorProposta')) - parseFloat(localStorage.getItem('ValorEntrada'));
    const calc2 = calc1 / parseInt(localStorage.getItem('NParcelas'), 10);
    setValorparc(calc2 || 0); // Garante que seja 0 se o cálculo for inválido
    console.log('Valor da parcela:', calc2);
  };
  const handleChanges = (values) => {
    const { formattedValue, value } = values;
    setValor(value); // Apenas o número puro, sem símbolos.
    console.log('valor Total Proposta: ' + valor * 10)
    localStorage.setItem('ValorProposta', valor * 10)

  };
  const handleEntrada = (values) => {
    const { floatValue } = values; // Pega o valor puro como número
    setEntrada(floatValue || 0); // Garante que o estado nunca seja null
    console.log("Valor Entrada: " + entrada * 10); // Multiplica o valor puro
    localStorage.setItem('ValorEntrada', entrada * 10)
  };


  const [telefone, setTelefone] = useState("");
  const handleChange = (event) => {
    setTelefone(event.target.value);
  };

  const [documento, setDocumento] = useState("CNPJ");
  const [numeroDocumento, setNumeroDocumento] = useState("");
  const [razaoSocial, setRazaoSocial] = useState("");
  const [cep, setCep] = useState("");
  const [enderecoCompleto, setEnderecoCompleto] = useState("");
  const [complemento, setComplemento] = useState("");
  const [bairro, setBairro] = useState("");
  const [cidade, setCidade] = useState("");
  const [uf, setUf] = useState("");
  const [situacao, setSituacao] = useState("");
  const [abertura, setAbertura] = useState("");
  const [autor, setAutor] = useState("");
  const [idAutor, setIdAutor] = useState("");


  const handleDocumentoChange = (e) => {
    setDocumento(e.target.value);
    setNumeroDocumento("");
    setRazaoSocial("");
  };

  const handleNumeroDocumentoChange = (e) => {
    const cleanedValue = e.target.value.replace(/[\.\-\/]/g, "");
    setNumeroDocumento(cleanedValue);
  };
  const handleNumeroDocumentoBlur = async () => {
    if (documento === "CNPJ" && numeroDocumento) {
      try {
        const response = await api.get(`/api/cnpj/${numeroDocumento}`);
        setRazaoSocial(response.data.nome || "Não encontrado");
        setCep(response.data.cep || "Não encontrado");
        setEnderecoCompleto(response.data.logradouro + ', ' + response.data.numero || "Não encontrado");
        setComplemento(response.data.complemento || "Não encontrado");
        setBairro(response.data.bairro || "Não encontrado")
        setCidade(response.data.municipio || "Não encontrado")
        setUf(response.data.uf || "Não encontrado")
        setSituacao(response.data.situacao || "")
        setAbertura(response.data.abertura || "")

      } catch (error) {
        console.error("Erro ao consultar o CNPJ:", error);
        setRazaoSocial("Erro ao consultar CNPJ");
      }
    }
  };
  useEffect(() => {
    api.get('/tipos')  // Substitua pela URL da sua API
      .then((response) => {
        if (!response.data.erro) {
          setTipos(response.data.dados);  // Preenche o select com os dados
        }
      })
      .catch((error) => {
        console.error('Erro ao buscar tipos:', error);
      });
  }, []);

  // Função para lidar com a mudança no select
  const handleTipoChange = (e) => {
    setSelectedTipo(e.target.value);
  };
  useEffect(() => {
    const fetchUltimoId = async () => {
      try {
        const response = await api.get("/propostas/ultimo-id"); // Substitua pela sua rota correta
        if (response.data) {
          const ultimoId = response.data.ultimoId; // Supondo que a API retorne algo como { ultimoId: 123 }
          setUltimoId(ultimoId);

          // Criar novo ID
          const dia = moment().format("DD"); // Obter o dia atual
          const ano = moment().format("YY"); // Obter o ano no formato YY
          const novoId = `${dia}${ultimoId + 1}${ano}`;
          setNovoIdProposta(novoId);
        }
      } catch (error) {
        console.error("Erro ao buscar último ID:", error);
      }
    };

    fetchUltimoId();
  }, []);
  useEffect(() => {
    const buscarUltimaProposta = async () => {
      try {
        const response = await api.get('/verUltimaProposta');
        if (!response.data.erro) {
          const ultimoId = response.data.dados.id;
          console.log('Última Proposta:', response.data.dados);
          setUltimoId(ultimoId);

          // Criar novo ID
          const dia = moment().format("DD"); // Obter o dia atual
          const ano = moment().format("YY"); // Obter o ano no formato YY
          const novoId = `${dia}${ultimoId + 1}${ano}`;
          setNovoIdProposta(novoId);
        } else {
          console.error('Erro:', response.data.mensagem);
        }
      } catch (error) {
        console.error('Erro ao buscar última proposta:', error);
      }
    };

    buscarUltimaProposta();
  }, []);
  const CalcParc = (e) => {
    const calc1 = parseFloat(localStorage.getItem('ValorProposta')) - parseFloat(localStorage.getItem('ValorEntrada'));
    const calc2 = calc1 / parseInt(localStorage.getItem('NParcelas'), 10);
    setValorparc(calc2 || 0); // Garante que seja 0 se o cálculo for inválido
    console.log('Valor da parcela:', calc2);
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Previne o envio do formulário padrão  
    // Remover os caracteres 'R$', '.', ',' e substituir por o formato correto


    const dadosProposta = {
      idProposta: novoIdProposta,
      razaoSocial,
      documento,
      numeroDocumento,
      tipo: selectedTipo,
      nomeContato,
      telefone,
      email,
      valor,
      entrada,
      parcelas,
      valorParcela,
      cep,
      enderecoCompleto,
      complemento,
      bairro,
      cidade,
      uf,
      conta,
      Autor: localStorage.getItem('NomeUsuario'),
      idAutor:localStorage.getItem('idusuario'),
    };


    console.log(dadosProposta); // Adicione este log para verificar os dados enviados


    try {
      const response = await api.post('/addProposta', dadosProposta);
      if (!response.data.erro) {
        alert('Proposta salva com sucesso!');
        localStorage.setItem('IdProposta',dadosProposta.idProposta )
        navigate('/AreaAdm/Propostas/SelecionarServico');

      } else {
        alert('Erro ao salvar proposta: ' + response.data.mensagem);
      }
    } catch (error) {
      console.error('Erro ao enviar proposta:', error);
      alert('Erro ao enviar proposta');
    }
  };


  return (
    <div className="flex-1 p-8 bg-gray-100">
      <h1 className="text-2xl font-bold mb-4">Criar Propostas</h1>

      <div className="bg-slate-100 rounded shadow">
        <form className=" pt-2 pl-5 pr-5" onSubmit={handleSubmit}>
          <div className="flex flex-col pt-2">
            <label htmlFor="idProposta" className="font-medium">ID da Proposta</label>
            <input
              type="text"
              id="idProposta"
              className="border rounded p-2 bg-gray-200"
              value={novoIdProposta}
              readOnly
            />
          </div>

          {/* Documento Empresa */}
          <div className="grid md:grid-cols-4 gap-4 pt-2">
            <div className="flex flex-col">
              <label htmlFor="documento" className="font-medium mb-1">Documento</label>
              <select
                id="documento"
                className="border border-gray-300 rounded-lg p-2"
                value={documento}
                onChange={handleDocumentoChange}
              >
                <option value="CPF">CPF</option>
                <option value="CNPJ">CNPJ</option>
              </select>
            </div>

            <div className="flex flex-col">
              <label htmlFor="numero" className="font-medium mb-1">Número</label>
              <input
                type="text"
                id="numero"
                className="border border-gray-300 rounded-lg p-2"
                placeholder="Digite o número"
                value={numeroDocumento}
                onChange={handleNumeroDocumentoChange}
                onBlur={handleNumeroDocumentoBlur}

              />
            </div>

            <div className="flex flex-col">
              {
                situacao != "INAPTA" ? (
                  <div className="border-2 bg-gray-300   rounded-lg">
                    <div className="flex flex-col">
                      <span className="flex justify-center">Abertura: </span>
                      <p className="flex justify-center">{abertura}</p>
                    </div>
                  </div>
                ) : (
                  <div className="border-2  rounded-lg">
                    <div className="flex flex-col">
                      <span className="flex justify-center">Abertura: </span>
                      <p className="flex justify-center">{abertura}</p>
                    </div>
                  </div>
                )
              }

            </div>
            {
              situacao != "INAPTA" ? (
                <div className="border-2  bg-green-600 text-white rounded-lg">
                  <div className="flex flex-col">
                    <span className="flex justify-center">Situação: </span>
                    <p className="flex justify-center">{situacao} </p>
                  </div>
                </div>
              ) : (
                <div className="border-2  bg-red-600 text-white rounded-lg">
                  <div className="flex flex-col">
                    <span className="flex justify-center">Situação: </span>
                    <p className="flex justify-center" >{situacao} </p>
                  </div>
                </div>
              )
            }




          </div>

          {/* Dados Empresa */}
          <div className="flex flex-col">
            <label htmlFor="razaoSocial" className="font-medium mb-1">Razão Social</label>
            <input
              type="text"
              id="razaoSocial"
              className="border border-gray-300 rounded-lg p-2"
              placeholder="Digite a razão social"
              value={razaoSocial}
              onChange={(e) => setRazaoSocial(e.target.value)} // Permite edição

            />
          </div>

          {/* Nome Cliente */}
          <div className="flex flex-col pt-2">
            <label htmlFor="nomeCliente" className="font-medium">Nome Cliente</label>
            <input
              type="text"
              id="nomeCliente"
              className="border rounded p-2"
              placeholder="Digite o nome do cliente"
              value={razaoSocial}
            />
          </div>

          {/* Tipo */}
          <div className="flex flex-col pt-2">
            <label htmlFor="tipo" className="font-medium">Tipo</label>
            <select
              id="tipo"
              className="border rounded p-2"
              value={selectedTipo}
              onChange={handleTipoChange}
              required
            >
              <option value="" disabled>Selecione o tipo</option>
              {tipos.map((tipo) => (
                <option key={tipo.id} value={tipo.Tipo}>
                  {tipo.Tipo}
                </option>
              ))}
            </select>
          </div>

          <div className="flex flex-col pt-2">
            <label htmlFor="cep" className="font-medium">CEP</label>
            <input
              type="text"
              id="cep"
              className="border rounded p-2"
              placeholder="Digite o cep"
              value={cep}
              onChange={(e) => setCep(e.target.value)} // Permite edição 
            />
          </div>


          {/* ---------------------------------------------Endereço------------------------------------------------------- */}
          <div className="grid grid-cols-2 gap-4">
            {/* Endereço Completo */}
            <div className="flex flex-col pt-2">
              <label htmlFor="enderecoCompleto" className="font-medium">Endereço Completo</label>
              <input
                type="text"
                id="enderecoCompleto"
                className="border rounded p-2"
                placeholder="Digite o endereço completo"
                value={enderecoCompleto}
                onChange={(e) => setEnderecoCompleto(e.target.value)} // Permite edição 
              />
            </div>

            {/* Complemento */}
            <div className="flex flex-col pt-2">
              <label htmlFor="complemento" className="font-medium">Complemento</label>
              <input
                type="text"
                id="complemento"
                className="border rounded p-2"
                placeholder="Digite o complemento"
                value={complemento}
                onChange={(e) => setComplemento(e.target.value)} // Permite edição 
              />
            </div>
          </div>

          <div className="grid grid-cols-1 lg:grid lg:grid-cols-3 gap-4">
            {/* Bairro */}
            <div className="flex flex-col pt-2">
              <label htmlFor="bairro" className="font-medium">Bairro</label>
              <input
                type="text"
                id="bairro"
                className="border rounded p-2"
                placeholder="Digite o bairro"
                value={bairro}
                onChange={(e) => setBairro(e.target.value)} // Permite edição 
              />
            </div>

            {/* Cidade */}
            <div className="flex flex-col pt-2">
              <label htmlFor="cidade" className="font-medium">Cidade</label>
              <input
                type="text"
                id="cidade"
                className="border rounded p-2"
                placeholder="Digite a cidade"
                value={cidade}
                onChange={(e) => setCidade(e.target.value)} // Permite edição 
              />
            </div>

            {/* UF */}
            <div className="flex flex-col pt-2">
              <label htmlFor="uf" className="font-medium">UF</label>
              <input
                type="text"
                id="uf"
                className="border rounded p-2"
                placeholder="Digite o estado (UF)"
                value={uf}
                onChange={(e) => setUf(e.target.value)} // Permite edição 
              />
            </div>
          </div>

          {/* ---------------------------------------------Contato------------------------------------------------------- */}
          <div className="grid grid-cols-1 lg:grid lg:grid-cols-4 gap-4">
            {/* Nome Contato */}
            <div className="flex flex-col pt-2">
              <label htmlFor="nomeContato" className="font-medium">Nome Contato</label>
              <input
                type="text"
                id="nomeContato"
                className="border rounded p-2"
                placeholder="Digite o nome do contato"
                value={nomeContato}
                onChange={(e) => setNomeContato(e.target.value)}  // Atualiza o valor
              />
            </div>

            {/* Whatsapp */}
            <div className="flex flex-col gap-2">
              <label htmlFor="telefone" className="text-sm font-medium">
                Telefone
              </label>
              <InputMask
                id="telefone"
                mask="(99) 9 9999-9999"
                value={telefone}
                onChange={handleChange}
                className="p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="(xx) x xxxx-xxxx"
              />
            </div>


            {/* Email */}
            <div className="flex flex-col pt-2">
              <label htmlFor="email" className="font-medium">Email</label>
              <input
                type="email"
                id="email"
                className="border rounded p-2"
                placeholder="Digite o email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}  // Atualiza o valor
              />

            </div>
          </div>

          {/* ---------------------------------------------Pagamento------------------------------------------------------- */}
          <div className="grid grid-cols-1 lg:grid lg:grid-cols-4 gap-4">

            {/* Valor */}
            <div className="flex flex-col pt-2">
              <label htmlFor="valor" className="font-medium">Valor</label>
              <NumericFormat
                id="valor"
                value={valor}
                onValueChange={handleChanges}
                thousandSeparator="."
                decimalSeparator=","
                prefix="R$ "
                placeholder="Digite o valor"
                className="border rounded p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                allowNegative={false}
                decimalScale={2}
                fixedDecimalScale
              />
            </div>

            {/* Entrada */}
            <div className="flex flex-col pt-2">
              <label htmlFor="entrada" className="font-medium">Entrada</label>
              <NumericFormat
                id="entrada"
                value={entrada} // Passa o valor atual para o campo
                onValueChange={handleEntrada} // Atualiza o estado com o valor puro
                thousandSeparator="." // Separador de milhar
                decimalSeparator="," // Separador decimal
                prefix="R$ " // Prefixo de moeda
                placeholder="Digite o valor"
                className="border rounded p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                allowNegative={false} // Não permite valores negativos
                decimalScale={2} // Número de casas decimais
                fixedDecimalScale // Mantém sempre as casas decimais
              />
            </div>

            {/* Forma de Pagamento */}
            <div className="flex flex-col pt-2">
              <label htmlFor="formaPagamento" className="font-medium">Parcelas</label>
              <input type="text" id="formaPagamento" className="border rounded p-2" placeholder="Digite a quantidade de parcelas" onBlur={handleParcela} />
            </div>
            <div className="flex flex-col pt-2">
              <label htmlFor="valorParcela" className="font-medium">Valor Parcelas </label>
              <input
                type="text"
                id="valorParcela"
                className="border rounded p-2 focus:outline-none focus:ring-2 focus:ring-blue-500 bg-slate-400"
                placeholder="Veja o valor de cada parcela"
                readOnly
                value={`R$ ${valorParcela.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
              />
            </div>

            {/* ---------------------------------------------Conta------------------------------------------------------- */}

            {/* Conta */}
            <div className="flex items-center space-x-4 text-lg bg-orange-200 border-2">
              <label className="flex items-center">
                <input
                  type="radio"
                  name="conta"
                  value="Inbravisa"
                  className="mr-2"
                  checked={conta === "Inbravisa"}
                  onChange={(e) => setConta(e.target.value)} // Atualiza o valor
                  required
                />
                Inbravisa
              </label>
              <label className="flex items-center">
                <input
                  type="radio"
                  name="conta"
                  value="Rui Valéria"
                  className="mr-2"
                  checked={conta === "Rui Valéria"}
                  onChange={(e) => setConta(e.target.value)} // Atualiza o valor
                />
                Rui
              </label>
              <label className="flex items-center">
                <input
                  type="radio"
                  name="conta"
                  value="Valéria"
                  className="mr-2"
                  checked={conta === "Valéria"}
                  onChange={(e) => setConta(e.target.value)} // Atualiza o valor
                />
                Valéria
              </label>
            </div>

          </div>
          {/* Botão de Enviar */}
          <div className="md:col-span-2 lg:col-span-3 flex justify-center mt-5">
            <button type="submit" className="bg-blue-500 text-white p-5 px-4 py-2 mb-5 rounded hover:bg-blue-600">
              <div className="grid grid-rows-1">
                <div className="flex items-center">
                  <BiSave className="text-xl" />
                  <span className="ml-2">Salvar Proposta</span>
                </div>

              </div>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AdicionarConsulta;

