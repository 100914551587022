import React from "react";

import MenuLateral from "../../../../Components-Adm/MenuLateral";
import Adicionar from "../../../../Components-Adm/AdicionarCliente";



export const AdicionarCliente = ()=>{
    return(
        <div className="flex">
        <MenuLateral />
        <Adicionar />        
        </div>
    )
}