import React from "react";

import MenuLateral from "../../../Components-Adm/MenuLateral";
import AdicionarUsuario from "../../../Components-Adm/AdicionarUsuario";



export const Adduser = ()=>{
    return(
        <div className="flex">
        <MenuLateral />
        <AdicionarUsuario />        
        </div>
    )
}