import React, { useEffect, useState } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Image,
} from "@react-pdf/renderer";
import logo from "../../Assets/logo.png"; // Se logo não for local, use um URL ou base64
import api from "../../config/apiAdm";

// Estilos para o PDF
const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 12,
    color: "#333",
  },
  header: {
    marginBottom: 20,
    textAlign: "center",
    borderBottomWidth: 1,
    borderBottomColor: "#ccc",
    paddingBottom: 10,
  },
  logo: {
    width: 120,
    height: 80,
    margin: "0 auto",
  },
  companyDetails: {
    fontSize: 10,
    textAlign: "center",
    marginVertical: 4,
  },
  contorno: {
    border: "1px solid #000",
    padding: 2,
  },
  boxHeader: {
    backgroundColor: "#444",
    color: "#fff",
    padding: 8,
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 16,
    marginBottom: 1,
  },
  tableRow: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#ddd",
  },
  tableCell: {
    padding: 1,
    textAlign: "left",
    justifyContent: "center",
    alignItems: "left",
    display: "flex",
  },
  grayCell: {
    backgroundColor: "#CCC",
    border: 1,
    width: "100%",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  footer: {
    marginTop: 30,
    textAlign: "center",
    fontSize: 10,
    color: "#ccc",
  },
});

// Função auxiliar para formatar os valores
const formatCurrency = (value) => {
  return parseFloat(value).toFixed(2).replace('.', ',');
};

// Componente de PDF
const InvoicePDF = ({ proposta, parcelas, servicos }) => {
  if (!proposta || !parcelas || !servicos) {
    return <Text>Carregando dados...</Text>;
  }

  // Converter logo para base64 (se necessário)
  const logoBase64 = `data:image/png;base64,${logo}`; // Coloque o conteúdo Base64 do logo aqui, se necessário

  return (
    <Document>
      {/* Página de Entrada */}
      <Page style={styles.page}>
        {/* Cabeçalho */}
        <View style={styles.header}>
          <Image style={styles.logo} src={logo} />
          <Text style={styles.companyDetails}>
            Av. Prof. Alfonso Bovero, 998 cj 16 | Perdizes - São Paulo-SP
          </Text>
          <Text style={styles.companyDetails}>
            Email: inbravisa@inbravisa.com.br | Tel: +55 11 3672-7001
          </Text>
        </View>

        <View style={styles.contorno}>
          {/* Cabeçalho da fatura */}
          <Text style={styles.boxHeader}>
            FATURA PARA PAGAMENTO DE PRESTAÇÃO DE SERVIÇOS
          </Text>

          {/* Informações da proposta */}
          <View style={styles.tableRow}>
            <View style={styles.grayCell}>
              <Text>Número da Proposta: {proposta.id_Proposta}</Text>
              <Text>Data de Emissão: {new Date(proposta.Data).toLocaleDateString()}</Text>
              <Text>Entrada</Text>
            </View>
            <View style={styles.grayCell}>
              <Text>VENCIMENTO</Text>
              <Text>{parcelas[0]?.data || "N/A"}</Text>
            </View>
          </View>

          {/* Razão Social e Documento */}
          <View style={styles.tableRow}>
            <View style={[styles.tableCell, { width: "70%" }]} >
              <Text>Razão Social</Text>
              <Text>{proposta["Razão Social"]}</Text>
            </View>
            <View style={[styles.tableCell, { width: "30%" }]} >
              <Text>{proposta.Documento}</Text>
              <Text>{proposta.Numero}</Text>
            </View>
          </View>

          {/* Endereço */}
          <View style={styles.tableRow}>
            <View style={[styles.tableCell, { width: "100%" }]} >
              <Text>Endereço:</Text>
              <Text>
                {proposta["Endereço Completo"]}, {proposta.Complemento}, {proposta.Bairro}, {proposta.Cidade} - {proposta.UF}
              </Text>
            </View>
          </View>

          {/* Serviços Prestados */}
          <View style={styles.tableRow}>
            <View style={styles.grayCell}>
              <Text>Serviços Prestados</Text>
            </View>
          </View>
          {servicos.length > 0 ? (
            servicos.map((servico, index) => (
              <View key={index} style={styles.tableRow}>
                <View style={styles.tableCell}>
                  <Text>{servico.descricao_Servico || "Descrição não disponível"}</Text>
                </View>
              </View>
            ))
          ) : (
            <View style={styles.tableRow}>
              <View style={styles.tableCell}>
                <Text>Nenhum serviço cadastrado para esta proposta.</Text>
              </View>
            </View>
          )}

          {/* Honorários, Impostos e Total */}
          <View style={styles.tableRow}>
            <View style={styles.grayCell}>
              <Text>Honorários</Text>
              <Text>R${formatCurrency(proposta.Entrada)}</Text>
            </View>
            <View style={styles.grayCell}>
              <Text>Impostos</Text>
              <Text>17%</Text>
            </View>
            <View style={styles.grayCell}>
              <Text>Total Impostos</Text>
              <Text>R${formatCurrency((parseFloat(proposta.Entrada) * 0.17).toFixed(2))}</Text>
            </View>
          </View>
        </View>

        {/* Dados para Pagamento */}
        <View style={styles.grayCell}>
          <Text>Dados para Pagamento</Text>
        </View>
        <View style={styles.tableRow}>
          {proposta.Conta === 'Inbravisa' ? (
            <View style={styles.tableCell}>
              <Text>Favorecido: INBRAVISA - Instituto Brasileiro de Auditoria em Vigilância Sanitária</Text>
              <Text>Banco Santander - 033</Text>
              <Text>Agência: 0389</Text>
              <Text>Conta Corrente: 130030498-0</Text>
              <Text>Chave Pix: CNPJ - 05.832.414/0001-09</Text>
            </View>
          ) : (
            <View style={styles.tableCell}>
              <Text>Favorecido: Rui de Andrade Dammenhain</Text>
              <Text>Banco do Brasil - 001</Text>
              <Text>Agência: 4417-2</Text>
              <Text>Conta Corrente: 50382-7</Text>
              <Text>Chave Pix: CPF - 064.395.818-56</Text>
            </View>
          )}
        </View>

        {/* Rodapé */}
        <Text style={styles.footer}>Inbravisa - Todos os direitos reservados</Text>
      </Page>

      {/* Páginas para as Parcelas */}
      {parcelas.map((parcela, index) => {
        const indexAdjusted = index + 1; // Ajustar o índice para começar em 1
        return (
          <Page key={indexAdjusted} style={styles.page}>
            {/* Cabeçalho */}
            <View style={styles.header}>
              <Image style={styles.logo} src={logo} />
              <Text style={styles.companyDetails}>
                Av. Prof. Alfonso Bovero, 998 cj 16 | Perdizes - São Paulo-SP -index: {indexAdjusted}
              </Text>
              <Text style={styles.companyDetails}>
                Email: inbravisa@inbravisa.com.br | Tel: +55 11 3672-7001
              </Text>
            </View>

            <View style={styles.contorno}>
              <Text style={styles.boxHeader}>
                FATURA PARA PAGAMENTO DE PRESTAÇÃO DE SERVIÇOS
              </Text>

              {/* Informações da parcela */}
              <View style={styles.tableRow}>
                <View style={styles.grayCell}>
                  <Text>Número da Proposta: {proposta.id_Proposta}</Text>
                  <Text>Data de Emissão: {new Date(proposta.Data).toLocaleDateString()}</Text>
                  <Text>Parcela {indexAdjusted}/{parcelas.length}</Text>
                </View>
                <View style={styles.grayCell}>
                  <Text>VENCIMENTO</Text>
                  <Text>{parcela.data || "N/A"}</Text>
                </View>
              </View>

              {/* Razão Social e Documento */}
              <View style={styles.tableRow}>
                <View style={[styles.tableCell, { width: "70%" }]}>
                  <Text>Razão Social</Text>
                  <Text>{proposta["Razão Social"]}</Text>
                </View>
                <View style={[styles.tableCell, { width: "30%" }]}>
                  <Text>{proposta.Documento}</Text>
                  <Text>{proposta.Numero}</Text>
                </View>
              </View>

              {/* Endereço */}
              <View style={styles.tableRow}>
                <View style={[styles.tableCell, { width: "100%" }]}>
                  <Text>Endereço:</Text>
                  <Text>
                    {proposta["Endereço Completo"]}, {proposta.Complemento}, {proposta.Bairro}, {proposta.Cidade} - {proposta.UF}
                  </Text>
                </View>
              </View>

              {/* Serviços Prestados */}
              <View style={styles.tableRow}>
                <View style={styles.grayCell}>
                  <Text>Serviços Prestados</Text>
                </View>
              </View>
              {servicos.length > 0 ? (
                servicos.map((servico, index) => (
                  <View key={index} style={styles.tableRow}>
                    <View style={styles.tableCell}>
                      <Text>{servico.descricao_Servico || "Descrição não disponível"}</Text>
                    </View>
                  </View>
                ))
              ) : (
                <View style={styles.tableRow}>
                  <View style={styles.tableCell}>
                    <Text>Nenhum serviço cadastrado para esta proposta.</Text>
                  </View>
                </View>
              )}

              {/* Honorários, Impostos e Total */}
              <View style={styles.tableRow}>
                <View style={styles.grayCell}>
                  <Text>Honorários</Text>
                  <Text>R${formatCurrency(parcela.valor)}</Text>
                </View>
                <View style={styles.grayCell}>
                  <Text>Impostos</Text>
                  <Text>17%</Text>
                </View>
                <View style={styles.grayCell}>
                  <Text>Total Impostos</Text>
                  <Text>R${formatCurrency((parseFloat(parcela.valor) * 0.17).toFixed(2))}</Text>
                </View>
              </View>
            </View>
          </Page>
        );
      })}

    </Document>
  );
};

// Componente principal
const Fatura = () => {
  const [loading, setLoading] = useState(true);
  const [proposta, setProposta] = useState(null);
  const [parcelas, setParcelas] = useState([]);
  const [servicos, setServicos] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const idProposta = localStorage.getItem("PesqProposta");
  
        if (!idProposta) {
          console.error("ID da proposta não encontrado no localStorage");
          alert("ID da proposta não encontrado no localStorage");
          return;
        }
  
        console.log("Buscando proposta com ID:", idProposta);
  
        // Buscar proposta
        const responseProposta = await api.get(`/verFaturas/${idProposta}`);
        if (responseProposta.data.erro) {
          console.error("Erro no backend:", responseProposta.data.mensagem);
          alert("Erro ao carregar os dados: " + responseProposta.data.mensagem);
          return;
        }
  
        const propostaData = responseProposta.data.dados;
  
        if (!propostaData) {
          console.error("Proposta não encontrada ou dados inválidos.");
          alert("Proposta não encontrada.");
          return;
        }
  
        console.log("Dados da proposta recebidos:", propostaData);
  
        // Verificar valor da entrada
        const valorEntrada = propostaData.valorEntrada
          ? parseFloat(propostaData.valorEntrada)
          : 0;
  
        const valorParcela = propostaData.valorParcela
          ? parseFloat(propostaData.valorParcela)
          : 0;
  
        // Determinar número de parcelas baseado na 'Forma de Pagamento'
        const numeroParcelas = propostaData["Forma de Pagamento"]
          ? parseInt(propostaData["Forma de Pagamento"], 10)
          : 0;
  
        // Calcular a entrada e as parcelas
        const entradaData = new Date(propostaData.Data);
        entradaData.setDate(entradaData.getDate() + 30); // Entrada 30 dias após a data base
  
        const parcelasData = [];
  
        // Data da primeira parcela: 1 mês após a entrada
        let ultimaParcelaData = new Date(entradaData);
        for (let i = 1; i < numeroParcelas; i++) {
          const novaParcelaData = new Date(ultimaParcelaData);
          novaParcelaData.setMonth(novaParcelaData.getMonth() + 1); // Adiciona 1 mês
          parcelasData.push({
            data: novaParcelaData.toLocaleDateString(),
            valor: valorParcela.toFixed(2), // Valor das parcelas
          });
          ultimaParcelaData = novaParcelaData; // Atualiza a última data para o próximo loop
        }
  
        // Incluir a entrada como a primeira parcela na lista completa
        const parcelasComEntrada = [
          {
            data: entradaData.toLocaleDateString(),
            valor: valorParcela.toFixed(2), // Valor da entrada
          },
          ...parcelasData, // Demais parcelas
        ];
  
        // Buscar serviços
        const responseServicos = await api.get(`/VerServicos/${idProposta}`);
        const servicosData = responseServicos.data.dados || [];
  
        setProposta(propostaData);
        setParcelas(parcelasComEntrada);
        setServicos(servicosData);
        setLoading(false);
      } catch (error) {
        console.error("Erro ao carregar os dados:", error);
        alert("Erro ao carregar os dados: " + error.message);
      }
    };
  
    fetchData();
  }, []);
  






  return loading ? (
    <Text>Carregando...</Text>
  ) : (
    <PDFViewer width="100%" height="1200">
      <InvoicePDF proposta={proposta} parcelas={parcelas} servicos={servicos} />
    </PDFViewer>
  );
};

export default Fatura;
