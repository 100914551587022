import { useState, useEffect } from "react";
import api from "../../config/apiAdm";

const AdicionarUsuario = () => {
  const [formData, setFormData] = useState({
    NivelAcesso: "",
    Usuario: "",
    Senha: "",
  });

  const [usuarios, setUsuarios] = useState([]);
  const [editingId, setEditingId] = useState(null);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  // Função para manipular as mudanças nos campos do formulário
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // Função para carregar usuários
  const carregarUsuarios = async () => {
    try {
      setLoading(true);
      const response = await api.get("/listarUsuarios");
      setUsuarios(response.data.usuarios);
    } catch (error) {
      console.error("Erro ao carregar usuários:", error);
    } finally {
      setLoading(false);
    }
  };

  // Função para envio do formulário
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      if (editingId) {
        // Atualizar usuário
        const response = await api.put(`/editarUsuario/${editingId}`, formData);
        if (!response.data.erro) {
          setMessage("Usuário atualizado com sucesso!");
        } else {
          setMessage("Erro ao atualizar usuário.");
        }
      } else {
        // Adicionar usuário
        const response = await api.post("/cadastrarLogin", formData);
        if (!response.data.erro) {
          setMessage("Usuário cadastrado com sucesso!");
        } else {
          setMessage("Erro ao cadastrar usuário.");
        }
      }

      setFormData({ NivelAcesso: "", Usuario: "", Senha: "" });
      setEditingId(null);
      carregarUsuarios();
    } catch (error) {
      console.error("Erro ao salvar usuário:", error);
      setMessage("Erro ao salvar usuário. Verifique os dados.");
    } finally {
      setLoading(false);
    }
  };

  // Função para excluir usuário
  const excluirUsuario = async (id) => {
    if (!window.confirm("Tem certeza que deseja excluir este usuário?")) return;

    try {
      setLoading(true);
      const response = await api.delete(`/excluirUsuario/${id}`);
      if (!response.data.erro) {
        setMessage("Usuário excluído com sucesso!");
        carregarUsuarios();
      } else {
        setMessage("Erro ao excluir usuário.");
      }
    } catch (error) {
      console.error("Erro ao excluir usuário:", error);
    } finally {
      setLoading(false);
    }
  };

  // Função para iniciar edição de um usuário
  const iniciarEdicao = (usuario) => {
    setEditingId(usuario.id);
    setFormData({
      NivelAcesso: usuario.NivelAcesso,
      Usuario: usuario.Usuario,
      Senha: "",
    });
  };

  // Cancelar edição
  const cancelarEdicao = () => {
    setEditingId(null);
    setFormData({ NivelAcesso: "", Usuario: "", Senha: "" });
  };

  // Carregar usuários ao montar o componente
  useEffect(() => {
    carregarUsuarios();
  }, []);

  return (
    <div className="flex flex-col lg:flex-row gap-2 p-8 w-full bg-gray-100">
      {/* Formulário de Cadastro */}
      <div className="flex-1">
        <h1 className="text-2xl font-bold mb-4">
          {editingId ? "Editar Usuário" : "Adicionar Usuário"}
        </h1>
        <form
          className="bg-white p-6 rounded shadow-md space-y-4 max-w-lg"
          onSubmit={handleSubmit}
        >
          {message && (
            <div
              className={`p-3 rounded ${message.includes("sucesso") ? "bg-green-100 text-green-700" : "bg-red-100 text-red-700"
                }`}
            >
              {message}
            </div>
          )}
          {/* Campo Nivel de Acesso */}
          <div>
            <label htmlFor="NivelAcesso" className="block text-sm font-medium text-gray-700">
              Nível de Acesso
            </label>
            <select
              id="NivelAcesso"
              name="NivelAcesso"
              value={formData.NivelAcesso}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            >
              <option value="">Selecione um nível de acesso</option>
              <option value="1">Acesso Total</option>
              <option value="2">Acesso Administrativo</option>
              <option value="3">Acesso Comercial</option>
            </select>
          </div>

          {/* Campo Usuário */}
          <div>
            <label htmlFor="Usuario" className="block text-sm font-medium text-gray-700">
              Usuário
            </label>
            <input
              type="text"
              id="Usuario"
              name="Usuario"
              value={formData.Usuario}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              placeholder="Digite o nome do usuário"
            />
          </div>

          {/* Campo Senha */}
          <div>
            <label htmlFor="Senha" className="block text-sm font-medium text-gray-700">
              Senha
            </label>
            <input
              type="password"
              id="Senha"
              name="Senha"
              value={formData.Senha}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              placeholder={editingId ? "Deixe vazio para manter a senha atual" : "Digite a senha"}
            />
          </div>

          {/* Botões */}
          <div className="flex space-x-4">
            <button
              type="submit"
              className="flex-1 py-2 px-4 rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              disabled={loading}
            >
              {loading ? "Salvando..." : editingId ? "Atualizar Usuário" : "Cadastrar Usuário"}
            </button>
            {editingId && (
              <button
                type="button"
                onClick={cancelarEdicao}
                className="py-2 px-4 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-gray-300 hover:bg-gray-400"
              >
                Cancelar
              </button>
            )}
          </div>
        </form>
      </div>

      {/* Lista de Usuários */}
      <div className="flex-1">
        <h2 className="text-2xl font-bold mb-4">Lista de Usuários</h2>
        <div className="bg-white p-6 rounded shadow-md">
          {loading ? (
            <p className="text-gray-500">Carregando...</p>
          ) : usuarios.length > 0 ? (
            <ul className="space-y-4">
              {usuarios.map((usuario) => (
                <li key={usuario.id} className="flex justify-between items-center border-b pb-2">
                  <div>
                    <p className="text-sm font-medium text-gray-700">Usuário: {usuario.Usuario}</p>
                    <p className="text-sm text-gray-500">Nível de Acesso: {usuario.NivelAcesso}</p>
                  </div>
                  <div className="flex space-x-2">
                    
                    <button
                      className="text-red-600 hover:underline"
                      onClick={() => excluirUsuario(usuario.id)}
                    >
                      Excluir
                    </button>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-gray-500">Nenhum usuário encontrado.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdicionarUsuario;
