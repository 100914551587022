import React, { useEffect, useState } from 'react';
import { Page, Text, View, Document, StyleSheet, PDFDownloadLink, Image, Font, PDFViewer } from '@react-pdf/renderer';
import api from '../../config/apiAdm';

import logo from '../../Assets/logo.png';
import ciesp from '../../Assets/certificado_CIESP.jpg';
import cvs from '../../Assets/certificado_CVS.jpg';
import assrui from '../../Assets/assrui.png';

// Desabilitar hifenização
Font.registerHyphenationCallback((word) => [word]);
// Estilos do documento
const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 12,
  },
  logo: {
    width: 150, // Largura do logotipo
    height: 100, // Altura do logotipo
    marginBottom: 20, // Espaço abaixo do logotipo
    alignSelf: 'center', // Centralizar o logotipo
  },
  assinatura: {
    width: 150, // Largura do logotipo
    height: 100, // Altura do logotipo
    marginBottom: -10, // Espaço abaixo do logotipo
    alignSelf: 'center', // Centralizar o logotipo    
  },
  textoAssinatura: {
    fontSize: 10,
    textAlign: 'center',
    marginBottom: 1,
    fontWeight: 'bold',

  },
  cvs: {

    marginBottom: 20, // Espaço abaixo do logotipo
    alignSelf: 'center', // Centralizar o logotipo
  },
  ciesp: {

    marginBottom: 20, // Espaço abaixo do logotipo
    alignSelf: 'center', // Centralizar o logotipo
  },
  textlogo1: {
    fontSize: 10,
    textAlign: 'center',
    marginBottom: 10,
    fontWeight: 'bold',
  },
  textlogo2: {
    fontSize: 10,
    textAlign: 'center',
    marginBottom: 20,
    fontWeight: 'bold',
    borderBottom: 1,
  },
  title: {
    fontSize: 16,
    textAlign: 'center',
    marginBottom: 20,
    fontWeight: 'bold',
  },
  section: {
    marginBottom: 10,
    padding: 10,
    border: '1px solid #000',
  },
  text: {
    marginBottom: 10,
    fontSize: 12,
    padding: 5,
    textAlign: 'justify',
    lineHeight: 1.5, // Ajuste o valor conforme necessário
  },
  textFixo: {
    marginBottom: 10,
    fontSize: 12,
    padding: 5,
    textAlign: 'justify',
    lineHeight: 1.5, // Ajuste o valor conforme necessário
  },
  titleMetodologia: {
    fontSize: 14,
    textAlign: 'justify',
    paddingTop: 30,
    marginTop: 30,
    marginBottom: 10,
    fontWeight: 'bold',
    borderTop: 1,
  },
  titleAnexo: {
    fontSize: 14,
    textAlign: 'justify',
    paddingTop: 5,
    marginTop: 5,
    marginBottom: 20,
    fontWeight: 'bold',

  },
  textMetodologia: {
    fontSize: 12,
    textAlign: 'justify',
    marginBottom: 10,
    fontWeight: 'bold',
    borderBottom: 1,
    paddingBottom: 30,
  },
  textAnexo: {
    fontSize: 12,
    textAlign: 'justify',
    fontWeight: 'bold',
    paddingBottom: 5,
  },
  textAutorizacao: {
    fontSize: 12,
    textAlign: 'justify',
    fontWeight: 'bold',
    paddingBottom: 25,
    lineHeight: 3,
  },
  textoAnexo: {
    fontSize: 10,
    textAlign: 'center',
    marginBottom: 20,
    fontWeight: 'bold',
    borderBottom: 1,
  },
  footer: {
    marginTop: 20,
    textAlign: 'center',
    fontSize: 10,
  },
});

const PropostaRealizada = () => {
  const idProposta = localStorage.getItem('PesqProposta');
  const [proposta, setProposta] = useState(null);
  const [servicos, setServivos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingService, setLoadingService] = useState(true);
  const [error, setError] = useState(null);
  const formatDate = (date) => {
    const parsedDate = new Date(date);
    return parsedDate.toLocaleDateString('pt-BR'); // Formato dd/mm/aaaa
  };

  // Busca os detalhes da proposta
  useEffect(() => {
    const fetchProposta = async () => {
      try {
        const response = await api.get(`/gerarProposta/${idProposta}`);
        if (!response.data.erro) {
          setProposta(response.data.dados);
        } else {
          setError(response.data.mensagem);
        }
      } catch (err) {
        setError('Erro ao buscar proposta');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchProposta();
  }, [idProposta]);

  // Busca os serviços relacionados à proposta
  useEffect(() => {
    const fetchServicos = async () => {
      try {
        const res = await api.get(`/ServicosProposta/${idProposta}`);
        if (!res.data.erro) {
          setServivos(res.data.dados);
        } else {
          setError(res.data.mensagem);
        }
      } catch (err) {
        setError('Erro ao buscar serviços');
        console.error(err);
      } finally {
        setLoadingService(false);
      }
    };

    fetchServicos();
  }, [idProposta]);
  const PropostaPDF = ({ proposta, servicos }) => (
    <Document>
      <Page style={styles.page}>
        {/* Logotipo */}
        <Image style={styles.logo} src={logo} />
        <Text style={styles.textlogo1}>Av. Prof. Alfonso Bovero, 998 cj 16 | Perdizes - São Paulo-SP</Text>
        <Text style={styles.textlogo2}>Email: inbravisa@inbravisa.com.br | Telefone: +55 11 3672-7001 | Website: www.inbravisa.com.br | Whatsapp: +55 11 99246-0319</Text>
        {/* Título Centralizado */}
        <Text style={styles.title}>PROPOSTA DE PRESTAÇÃO DE SERVIÇOS TÉCNICOS</Text>

        {/* Informações Gerais com Borda */}
        <View style={styles.section}>
          <Text style={styles.text}>Razão Social: {proposta['Razão Social']}</Text>
          <Text style={styles.text}>{proposta.Documento}: {proposta.Numero}</Text>
          <Text style={styles.text}>Tipo de Estabelecimento: {proposta.Tipo}</Text>
          <Text style={styles.text}>Data: {formatDate(proposta.Data)}</Text>
        </View>

        <Text style={styles.textFixo}>Após nosso contato apresento a proposta do INBRAVISA - Instituto Brasileiro de Auditoria em
          Vigilância Sanitária, sobre os trabalhos necessários e seus respectivos custos para a adequação
          frente às exigências da legislação sanitária vigente.</Text>
        <Text style={styles.textFixo}>
          Gostaríamos de ressaltar que o processo de implantação e regularização envolvem questões
          que compreendem a adoção de medidas administrativas, técnicas e de adequação de áreas
          físicas, e que se desenvolvem através de um processo de implantação gradativa.</Text>
        <Text style={styles.textFixo}>
          A responsabilidade pela implantação das medidas propostas é do contratante, cabendo ao
          INBRAVISA - Instituto Brasileiro de Auditoria em Vigilância Sanitária fornecer orientações e
          diretrizes não arcando com custos de obras para a regularização.</Text>
        <Text style={styles.textFixo}>
          Custos relativos a taxas e demais emolumentos deverão ser pagas pelo contratante, não
          cabendo ao INBRAVISA - Instituto Brasileiro de Auditoria em Vigilância Sanitária a
          responsabilidade por esses custos.</Text>
      </Page>

      <Page style={styles.page}>
        <Text style={styles.titleMetodologia}>Metodologia</Text>
        <Text style={styles.textMetodologia}>A metodologia do INBRAVISA - Instituto Brasileiro de Auditoria em Vigilância Sanitária baseia-se
          no acompanhamento contínuo da implantação destas medidas, orientando e assessorando o
          contratante para a sua devida regularização frente às exigências sanitárias.
        </Text>
        <Text style={styles.titleAnexo}>Anexos</Text>
        <Text style={styles.textAnexo}>Esta proposta é composta de anexos com o seguinte teor:</Text>
        <Text style={styles.textAnexo}>Anexo 1: Autorização para início das atividades.</Text>
        <Text style={styles.textAnexo}>Anexo 2: Relação de documentos necessários para início dos trabalhos.</Text>
        <Text style={styles.textAnexo}>Anexo 3: Certificado de filiação do INBRAVISA junto ao CIESP.</Text>
        <Text style={styles.textMetodologia}>Anexo 4: Diploma de Especialista em Vigilância Sanitária</Text>

        <Text style={styles.titleAnexo}>Valores</Text>
        {/* Valores */}
        <Text style={styles.text}>Valor Total: R$ {proposta.Valor}</Text>

        <Text style={styles.text}>Sendo da seguinte forma:</Text>

        {/* Forma de Pagamento */}
        <Text style={styles.text}>Ato de: R${proposta.Entrada || 'Não especificado'},00
          mais {proposta['Forma de Pagamento'] || 'Não especificado'}x de R$ {proposta.valorParcela},00</Text>

        {/* Dados Bancários */}
        <Text style={styles.titleMetodologia}>Dados Bancários</Text>



        {proposta.Conta === 'Inbravisa' ? (
          <View style={styles.text}>
            <Text style={styles.textAnexo}>Favorecido: INBRAVISA - Instituto Brasileiro de Auditoria em Vigilância Sanitária</Text>
            <Text style={styles.textAnexo}>Banco Santander - 033 </Text>
            <Text style={styles.textAnexo}>Agência: 0389 </Text>
            <Text style={styles.textAnexo}>Conta Corrente: 130030498-0</Text>
            <Text style={styles.textAnexo}>Chave Pix: CNPJ - 05.832.414/0001-09</Text>
          </View>)

          :
          (<View style={styles.text}>
            <Text style={styles.text}>Favorecido: Rui de Andrade Dammenhain </Text>
            <Text style={styles.text}>Banco do Brasil - 001 </Text>
            <Text style={styles.text}>Agência: 4417-2</Text>
            <Text style={styles.text}>Conta Corrente: 50382-7</Text>
            <Text style={styles.text}>Chave Pix: CPF - 064.395.818-56</Text>
          </View>)

          || 'Não especificado'}
      </Page>


      <Page style={styles.page}>
        {/* Serviços Relacionados */}
        {servicos && servicos.length > 0 && (
          <>
            <Text style={styles.text}>Atividades Envolvidas:</Text>
            {servicos.map((servico, index) => (
              <Text key={index} style={styles.text}>
                {index + 1}. {servico.descricao_Servico}
              </Text>
            ))}
          </>
        )}


      </Page>
      <Page style={styles.page}>

        <Text style={styles.titleMetodologia}>Inicio dos Trabalhos</Text>
        <Text style={styles.textMetodologia}>Os trabalhos serão iniciados tão logo o INBRAVISA receba a confirmação do deposito bancário
          referente à primeira parcela o recebimento da autorização devidamente assinada. O restante da
          documentação deverá ser enviado o mais breve possível.
        </Text>

        <Text style={styles.titleAnexo}>Considerações Finais</Text>
        <Text style={styles.textAnexo}
        >Deixamos claro que esta previsão de investimentos representa uma prestação de serviços com a
          finalidade de regularizar a empresa diante dos diversos órgãos de fiscalização envolvidos no
          processo.</Text>
        <Text style={styles.textAnexo}>
          Aguardamos sua manifestação de aprovação para a emissão da respectiva ordem de serviço
          onde a presente proposta é parte integrante.</Text>
        <Text style={styles.textAnexo}>
          Esta aprovação poderá ser feita via endereço eletrônico.
          Atenciosamente,
        </Text>

        <Image style={styles.assinatura} src={assrui} />
        <Text style={styles.textoAssinatura}>Rui de Andrade Dammenhain</Text>
        <Text style={styles.textoAssinatura}>Diretor Presidente</Text>
        <Text style={styles.textoAssinatura}>INBRAVISA - Instituto Brasileiro de Auditoria em Vigilância Sanitária</Text>
        <Text style={styles.textoAssinatura}>Avenida Prof. Alfonso Bovero, 998 - Conj. 16 - Perdizes - São Paulo - SP</Text>
        <Text style={styles.textoAssinatura}>CEP 05019-010. TEL (11) 3672.7001 - inbravisa@inbravisa.com.br</Text>
        <Text style={styles.textoAssinatura}>www.inbravisa.com.br</Text>
        <Text style={styles.textoAssinatura}>Rui de Andrade Dammenhain</Text>

      </Page>

      <Page style={styles.page}>
        <Text style={styles.titleAnexo}>Anexo I - Autorização</Text>
        <Text style={styles.textAutorizacao}>
          Autorização para Início de trabalho
          a empresa {proposta['Razão Social']} contrata o INBRAVISA - Instituto Brasileiro de Auditoria em Vigilância
          Sanitária para realizar as atividades acima descritas e comprometo-me a realizar os pagamentos
          acordados.</Text>
        <Text style={styles.textAutorizacao}> Assinatura ______________________________________ DATA:____/____/________</Text>

      </Page>

      <Page style={styles.page}>
        <Text style={styles.titleAnexo}>Anexo II - Relação de documentos necessários para início dos trabalhos</Text>
        <Text style={styles.textAutorizacao}>Documentos de Pessoa Jurídica:</Text>
        <Text style={styles.textAutorizacao}>Cartão CNPJ - Cópia Simples;</Text>
        <Text style={styles.textAutorizacao}>Contrato Social - Cópia Simples registrada.</Text>
        <Text style={styles.textAutorizacao}>Esses documentos são para início de trabalho, demais documentos serão pedidos conforme a
          sua necessidade, caso não seja entregue, pode inviabilizar os serviços prestados pelo INBRAVISA</Text>


      </Page>
      <Page style={styles.page}>
        <Text style={styles.titleAnexo}>Anexo III -  Diploma de Empresa Parceira CIESP</Text>
        <Image style={styles.ciesp} src={ciesp} />


      </Page>
      <Page style={styles.page}>
        <Text style={styles.titleAnexo}>Anexo IV -  Diploma de Especialista em Vigilância Sanitária</Text>
        <Image style={styles.cvs} src={cvs} />


      </Page>
    </Document>
  );

  if (loading || loadingService) return <p>Carregando...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className='grid-flow-row pl-40 pr-40 pt-20'>
      <div className='mb-20'>
        <PDFDownloadLink className='m-20 bg-sky-700 text-white hover:bg-sky-500 rounded-xl p-5'
        document={<PropostaPDF proposta={proposta} servicos={servicos} />}
        fileName={`Proposta ${proposta['Razão Social']}.pdf`}
      >
        {({ loading }) =>
          loading ? 'Carregando documento...' : (<>Baixar Proposta em PDF para {proposta['Razão Social']} </>)
        }
      </PDFDownloadLink>
      </div>
      <div>
        <PDFViewer width="100%" height="1200">
            <PropostaPDF proposta={proposta} servicos={servicos} />
      </PDFViewer>
      </div>
      
    </div>
    
  );
};

export default PropostaRealizada;
