import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import ptBrLocale from '@fullcalendar/core/locales/pt-br'; // Importa o idioma
import api from "../../config/apiAdm";

const CalendarApp = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showForm, setShowForm] = useState(false); // Controla a exibição do formulário
  const [showView, setShowView] = useState(false); // Controla a exibição da visualização do evento
  const [newEvent, setNewEvent] = useState({
    id: '',
    title: '',
    startDate: '',
    startTime: '',
    endDate: '',
    endTime: '',
    creator: '',
    idcreator: '',
    description: '',
  });
  const [viewEvent, setViewEvent] = useState({
    id: '',
    title: '',
    start: '',
    end: '',
    creator: '',
    idcreator: '',
    description: ''
  });

  // Buscar eventos do servidor ao carregar a página
  useEffect(() => {
    api.get('/events')
      .then(response => {
        setEvents(response.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Erro ao carregar eventos', error);
        setLoading(false);
      });
  }, []);

  //formata o valor clicado
  const formatDateForInput = (date) => {
    const d = new Date(date);
    d.setMinutes(d.getMinutes() + d.getTimezoneOffset()); // Ajusta o fuso horário
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const day = String(d.getDate()).padStart(2, '0');
    const hours = '00';
    const minutes = '00';
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  // Exibir o formulário de adição de evento
  const handleDateClick = (info) => {
    const date = new Date(info.dateStr); // Converte para objeto Date
    const formattedDate = date.toISOString().split('T')[0]; // Extrai apenas a data no formato "yyyy-MM-dd"
    const formattedTime = '00:00'; // Define a hora inicial padrão como meia-noite

    setNewEvent({
      ...newEvent,
      startDate: formattedDate, // Define a data de início
      startTime: formattedTime, // Define o horário de início
      endDate: formattedDate,   // Define a data de término como a mesma por padrão
      endTime: formattedTime,   // Define o horário de término como o mesmo por padrão
      creator: localStorage.getItem('NomeUsuario'),
      idcreator: localStorage.getItem('idusuario'),
    });
    setShowForm(true); // Exibe o formulário para criar/editar o evento
  };


  // Atualizar os campos do evento
  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewEvent((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  // Submeter o evento
  const handleSubmit = (e) => {
    e.preventDefault();

    const start = `${newEvent.startDate}T${newEvent.startTime}`;
    const end = `${newEvent.endDate}T${newEvent.endTime}`;

    const eventToSubmit = {
      ...newEvent,
      start,
      end,
    };

    delete eventToSubmit.startDate;
    delete eventToSubmit.startTime;
    delete eventToSubmit.endDate;
    delete eventToSubmit.endTime;

    if (newEvent.id) {
      // Atualizar evento
      api.put(`/events/${newEvent.id}`, eventToSubmit)
        .then(response => {
          setEvents((prev) =>
            prev.map((event) =>
              event.id === newEvent.id ? response.data : event
            )
          );
          setShowForm(false);
        })
        .catch((error) => console.error('Erro ao editar evento', error));
    } else {
      // Criar novo evento
      api.post('/events', eventToSubmit)
        .then((response) => {
          setEvents((prev) => [...prev, response.data]);
          setShowForm(false);
        })
        .catch((error) => console.error('Erro ao adicionar evento', error));
    }
  };


  // Editar evento
  const handleEventClick = (info) => {
    setViewEvent({
      id: info.event.id,
      title: info.event.title,
      start: info.event.start.toISOString(),
      end: info.event.end ? info.event.end.toISOString() : info.event.start.toISOString(),
      creator: localStorage.getItem('NomeUsuario'),
      idcreator: localStorage.getItem('idusuario'),
      description: info.event.extendedProps.description || '',  // Pega a descrição original
    });
    setShowView(true); // Abre o formulário de visualização
  };

  // Excluir evento e atualizar os eventos do calendário
  const handleDeleteEvent = () => {
    const eventId = viewEvent.id; // Obtém o id do evento da visualização
    if (window.confirm('Deseja realmente excluir este evento?')) {
      api.delete(`/events/${eventId}`)
        .then(() => {
          // Recarregar eventos do servidor após exclusão
          api.get('/events')
            .then(response => {
              setEvents(response.data); // Atualiza os eventos no estado
              setShowView(false); // Fecha o modal de visualização
              alert('Evento excluído com sucesso!'); // Feedback para o usuário
            })
            .catch(error => {
              console.error('Erro ao recarregar eventos', error);
              alert('Erro ao recarregar eventos. Tente novamente.');
            });
        })
        .catch(error => {
          console.error('Erro ao excluir evento', error);
          alert('Erro ao excluir o evento. Tente novamente.');
        });
    }
  };


  // Atualizar evento após ser movido (eventDrop)
  const handleEventDrop = (info) => {
    const updatedEvent = {
      id: info.event.id,
      title: info.event.title,
      start: info.event.start.toISOString(),
      end: info.event.end ? info.event.end.toISOString() : info.event.start.toISOString(),
      creator: localStorage.getItem('NomeUsuario'),
      idcreator: localStorage.getItem('idusuario'),
      description: info.event.extendedProps.description || '',
    };

    api.put(`/events/${info.event.id}`, updatedEvent)
      .then(response => {
        setEvents(prevEvents =>
          prevEvents.map(event =>
            event.id === info.event.id ? response.data : event
          )
        );
      })
      .catch(error => {
        console.error('Erro ao atualizar evento movido', error);
      });
  };


  return (
    <div className="container mx-auto mt-6">
      <h1 className="text-2xl font-bold text-center mb-4">Calendário Interativo</h1>

      {!loading ? (
        <>
          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
            headerToolbar={{
              left: 'prev,next today',
              center: 'title',
              right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
            }}
            initialView="dayGridMonth"
            events={events}
            editable={true}
            selectable={true}
            dateClick={handleDateClick}
            eventClick={handleEventClick}  // Agora o clique do evento trata editar/excluir
            eventDrop={handleEventDrop}  // Atualiza o evento após ser movido
            locale={ptBrLocale} //colocar em PT-BR
          />

          {/* Formulário de Adição ou Edição de Evento */}
          {showForm && (
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-40">
              <div className="bg-white p-6 rounded-lg w-full max-w-4xl mx-4 z-50">
                <h2 className="text-xl font-bold mb-4">{newEvent.id ? 'Editar Evento' : 'Adicionar Evento'}</h2>
                <form onSubmit={handleSubmit}>
                  <div className="flex flex-row gap-4 mb-4">
                    <div className="flex-1">
                      <input
                        type="hidden"
                        id="creator"
                        name="creator"
                        value={newEvent.creator}
                        onChange={handleChange}
                        className="mt-1 p-2 border rounded-md w-full bg-gray-300"
                        readOnly
                      />
                    </div>

                    <div className="flex-1">
                      <input
                        type="hidden"
                        id="idcreator"
                        name="idcreator"
                        value={newEvent.idcreator}
                        onChange={handleChange}
                        className="mt-1 p-2 border rounded-md w-full bg-gray-300"
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="mb-4">
                    <label htmlFor="title" className="block text-sm font-medium text-gray-700">
                      Título
                    </label>
                    <input
                      type="text"
                      id="title"
                      name="title"
                      value={newEvent.title}
                      onChange={handleChange}
                      className="mt-1 p-2 border rounded-md w-full"
                      required
                    />
                  </div>

                  <div className="mb-4">
                    <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                      Descrição
                    </label>
                    <textarea
                      id="description"
                      name="description"
                      value={newEvent.description}
                      onChange={handleChange}
                      className="mt-1 p-2 border rounded-md w-full"
                      rows="4"
                    />
                  </div>
                  <div className="grid grid-cols-2 gap-4 mb-4">
                    <div>
                      <label htmlFor="startDate" className="block text-sm font-medium text-gray-700">
                        Data de Início
                      </label>
                      <input
                        type="date"
                        id="startDate"
                        name="startDate"
                        value={newEvent.startDate}
                        onChange={handleChange}
                        className="mt-1 p-2 border rounded-md w-full"
                        required
                      />
                    </div>
                    <div>
                      <label htmlFor="startTime" className="block text-sm font-medium text-gray-700">
                        Hora de Início
                      </label>
                      <input
                        type="time"
                        id="startTime"
                        name="startTime"
                        value={newEvent.startTime}
                        onChange={handleChange}
                        className="mt-1 p-2 border rounded-md w-full"
                        required
                      />
                    </div>
                    <div>
                      <label htmlFor="endDate" className="block text-sm font-medium text-gray-700">
                        Data de Término
                      </label>
                      <input
                        type="date"
                        id="endDate"
                        name="endDate"
                        value={newEvent.endDate}
                        onChange={handleChange}
                        className="mt-1 p-2 border rounded-md w-full"
                        required
                      />
                    </div>
                    <div>
                      <label htmlFor="endTime" className="block text-sm font-medium text-gray-700">
                        Hora de Término
                      </label>
                      <input
                        type="time"
                        id="endTime"
                        name="endTime"
                        value={newEvent.endTime}
                        onChange={handleChange}
                        className="mt-1 p-2 border rounded-md w-full"
                        required
                      />
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <button
                      type="button"
                      onClick={() => setShowForm(false)}
                      className="bg-gray-600 text-white px-4 py-2 rounded-md"
                    >
                      Cancelar
                    </button>
                    <button
                      type="submit"
                      className="bg-blue-500 text-white px-4 py-2 rounded-md"
                    >
                      {newEvent.id ? 'Salvar' : 'Adicionar'}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}


          {/* Modal de Visualização do Evento */}
          {showView && (
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50">
              <div className="bg-white p-6 rounded-lg w-96 z-50">
                <h2 className="text-xl font-bold mb-4">Visualizar Evento</h2>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">Título</label>
                  <p>{viewEvent.title}</p>
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">Descrição</label>
                  <p>{viewEvent.description}</p>
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">Data de Início</label>
                  <p>{new Date(viewEvent.start).toLocaleString()}</p>
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">Data de Término</label>
                  <p>{new Date(viewEvent.end).toLocaleString()}</p>
                </div>
                <div className="flex justify-between">
                  <button
                    type="button"
                    onClick={() => setShowView(false)}
                    className="bg-gray-600 text-white px-4 py-2 rounded-md"
                  >
                    Fechar
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setShowView(false); // Fechar o modal de visualização
                      setShowForm(true);  // Mostrar o formulário de edição
                      setNewEvent(viewEvent); // Definir o evento no formulário para edição
                    }}
                    className="bg-yellow-500 text-white px-4 py-2 rounded-md"
                  >
                    Editar
                  </button>
                  <button
                    type="button"
                    onClick={handleDeleteEvent}
                    className="bg-red-500 text-white px-4 py-2 rounded-md"
                  >
                    Excluir
                  </button>
                </div>
              </div>
            </div>
          )}

        </>
      ) : (
        <p>Carregando eventos...</p>
      )}
    </div>
  );
};

export default CalendarApp;
