import React from "react";

import MenuLateral from "../../../Components-Adm/MenuLateral";
import Calendario from "../../../Components-Adm/Calendario";



export const Agenda = ()=>{
    return(
        <div className="flex">
        <MenuLateral />
        <div className=" w-full pl-28 pr-28">
            <Calendario />  
        </div>
              
        </div>
    )
}