import React from "react";

import MenuLateral from "../../../../Components-Adm/MenuLateral";
import Financa from "../../../../Components-Adm/Financeiro";



export const Financeiro = ()=>{
    return(
        <div className="flex">
        <MenuLateral />
        <Financa />        
        </div>
    )
}