import React from "react";

import MenuLateral from "../../../Components-Adm/MenuLateral";
import Conultas from "../../../Components-Adm/AdicionarConsulta";



export const Consulta = ()=>{
    return(
        <div className="flex">
        <MenuLateral />
        <Conultas />        
        </div>
    )
}