import React from "react";

import MenuLateral from "../../../../Components-Adm/MenuLateral";
import Realizada from "../../../../Components-Adm/PropostaRealizada";



export const PropostaFeita = ()=>{
    return(
        <div className="flex">
        <MenuLateral />
        <Realizada />        
        </div>
    )
}