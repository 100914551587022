import React from "react";


const AdicionarConsulta = () => {
 
  return (
   <><h1>Sistema de consulta</h1></>
  );
};

export default AdicionarConsulta;

