import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import api from "../../config/apiAdm";

const VerFichaCliente = () => {
    const [dadosCliente, setDadosCliente] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [logotipo, setLogotipo] = useState("");
    const [erroLogo, setErroLogo] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [showDocModal, setShowDocModal] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [documents, setDocuments] = useState([]);
    const [servicos, setServicos] = useState([]);
    const [logoexist, setLogoExist] = useState(null);
    const [selectedServico, setSelectedServico] = useState(null); // Para armazenar o serviço selecionado
    const [newAndamento, setNewAndamento] = useState(""); // Para armazenar o novo andamento
    const [showAndamentoModal, setShowAndamentoModal] = useState(false); // Para controlar a visibilidade do modal




    const handleDeleteDocument = async (id) => {
        const confirmDelete = window.confirm("Tem certeza que deseja excluir este documento?");
        if (!confirmDelete) return;

        try {
            const response = await api.delete(`/deletarDocumento/${id}`);
            if (response.status === 200) {
                alert("Documento deletado com sucesso!");
                fetchDocuments(); // Atualiza a lista de documentos
            } else {
                alert("Erro ao deletar documento.");
            }
        } catch (error) {
            console.error("Erro ao excluir o documento:", error);
            alert("Erro ao excluir o documento.");
        }
    };

    const openAndamentoModal = (servico) => {
        setSelectedServico(servico);
        setNewAndamento(servico.andamento); // Define o andamento atual no estado
        setShowAndamentoModal(true);
    };

    const handleUpload = async () => {
        if (!selectedFile) {
            alert("Por favor, selecione um arquivo.");
            return;
        }

        const formData = new FormData();
        formData.append("file", selectedFile);

        const idProposta = localStorage.getItem("IdProposta");
        if (!idProposta) {
            alert("IdProposta não encontrado no localStorage.");
            return;
        }

        formData.append("id_proposta", idProposta);

        try {
            setUploading(true); // Inicia o estado de carregamento do upload
            const response = await api.post("/upload", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });

            if (response.status === 200) {
                alert("Upload realizado com sucesso!");
                fetchLogotipo(); // Atualiza a imagem automaticamente
                setShowModal(false); // Fecha o modal após o upload
            } else {
                alert("Falha ao fazer upload.");
            }
        } catch (error) {
            console.error("Erro ao fazer upload:", error);
            alert("Erro ao fazer upload.");
        } finally {
            setUploading(false); // Finaliza o estado de carregamento
        }
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop: (acceptedFiles) => {
            setSelectedFile(acceptedFiles[0]);
        },
    });

    const fetchLogotipo = async () => {
        try {
            const idProposta = localStorage.getItem("IdProposta");

            const response = await api.get(`/Logotipo/${idProposta}`);
            const data = response.data;

            if (data.erro) {
                const url =
                    "https://www.inbravisa.com.br/controle/bemvindo/gerar_certificado/Fotos/padrao.jpg";
                setLogotipo(url);
                setLogoExist('nao')
            } else {
                const url = `https://www.inbravisa.com.br/${data.logotipo}`;
                setLogotipo(url);
                setLogoExist('sim')
            }
        } catch (error) {
            console.error("Erro ao buscar logotipo:", error);
            setErroLogo("Imagem não encontrada.");
            const url =
                "https://www.inbravisa.com.br/controle/bemvindo/gerar_certificado/Fotos/padrao.jpg";
            setLogotipo(url);
        }
    };

    useEffect(() => {
        fetchLogotipo();
    }, []);




    // Fetch documentos
    const fetchDocuments = async () => {
        const idProposta = localStorage.getItem("IdProposta");
        try {
            const response = await api.get(`/VerDocumentos/${idProposta}`);
            if (response.data.erro) {
                alert("Erro ao carregar documentos.");
            } else {
                setDocuments(response.data.dados || []);
            }
        } catch (error) {
            console.error("Erro ao carregar documentos:", error);
        }
    };
    useEffect(() => {
        fetchDocuments();
    }, []);


    const fetchDadosCliente = async () => {
        const idProposta = localStorage.getItem("IdProposta");

        if (!idProposta) {
            setError("ID da proposta não encontrado no localStorage.");
            setLoading(false);
            return;
        }

        try {
            const response = await api.get(`/VerCliente/${idProposta}`);
            if (response.data.erro) {
                setError(response.data.mensagem || "Erro ao buscar dados do cliente.");
            } else {
                setDadosCliente(response.data.dados);
            }
        } catch (err) {
            console.error("Erro ao buscar ficha do cliente:", err.message);
            setError("Erro ao buscar ficha do cliente.");
        } finally {
            setLoading(false);
        }
    };

    // Fetch Servicos

    const fetchServicos = async () => {
        const idProposta = localStorage.getItem("IdProposta");
        try {
            const responses = await api.get(`/VerServicos/${idProposta}`);
            if (responses.data.erro) {
                alert("Erro ao carregar os serviços.");
            } else {
                setServicos(responses.data.dados || []);
                console.log("Serviços carregados:", responses.data.dados);  // Confirma que os dados estão sendo recebidos
            }
        } catch (error) {
            console.error("Erro ao carregar documentos:", error);
        }
    };


    useEffect(() => {
        fetchDadosCliente();
        fetchServicos();
    }, []);

    const handleUploadDoc = async (formData) => {
        try {
            const response = await api.post("/uploadDocumentos", formData, {
                headers: { "Content-Type": "multipart/form-data" },
            });
            if (response.status === 200) {
                alert("Documento enviado com sucesso!");
                fetchDocuments(); // Atualizar lista após upload
                setShowDocModal(false);
            } else {
                alert("Erro ao enviar documento.");
            }
        } catch (error) {
            console.error("Erro ao enviar documento:", error);
            alert("Erro ao processar o envio.");
        }
    };

    useEffect(() => {
        fetchDocuments();
    }, []);


    if (loading) {
        return <p>Carregando ficha do cliente...</p>;
    }

    if (error) {
        return <p>{error}</p>;
    }

    const handleUpdateAndamento = async () => {
        if (!newAndamento || !selectedServico) {
            alert("Selecione um andamento.");
            return;
        }

        try {
            // Envia a atualização para a API
            const response = await api.put(`/AtualizarAndamento/${selectedServico.id}`, {
                andamento: newAndamento,
            });

            if (response.data.erro) {
                alert("Erro ao atualizar andamento.");
            } else {
                alert("Andamento atualizado com sucesso!");

                // Atualizar a lista de serviços localmente
                const updatedServicos = servicos.map((servico) =>
                    servico.id === selectedServico.id
                        ? { ...servico, andamento: newAndamento } // Atualiza o andamento do serviço alterado
                        : servico
                );
                setServicos(updatedServicos); // Atualiza o estado de serviços

                setShowAndamentoModal(false); // Fecha o modal
            }
        } catch (error) {
            console.error("Erro ao atualizar andamento:", error);
            alert("Erro ao atualizar andamento.");
        }
    };

    // Função para determinar a classe da validade
    const validadeClass = (validade) => {
        const validadeDate = new Date(validade);
        const today = new Date();
        const diffTime = validadeDate - today; // Diferença em milissegundos
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // Converte para dias

        if (diffDays < 0) {
            return "bg-red-600 text-white rounded-lg p-2"; // Vencido
        } else if (diffDays <= 90) {
            return "bg-yellow-500 text-white rounded-lg p-2"; // Até 90 dias
        } else {
            return "bg-green-600 text-white rounded-lg p-2"; // Mais de 90 dias
        }
    };
    return (
        <div className="container mx-auto p-8">
            <div className="bg-white shadow-lg rounded-lg p-8 border">
                <h1 className="text-3xl font-semibold mb-6 text-center text-gray-800 border-b pb-4">
                    Ficha do Cliente
                </h1>
                <div className="grid gap-6 md:grid-cols-3">
                    <div className="flex flex-col items-center justify-center border rounded-lg p-4">
                        {erroLogo ? (
                            <>
                                <p className="text-red-500">{erroLogo}</p>
                                <img
                                    src="https://www.inbravisa.com.br/controle/bemvindo/gerar_certificado/Fotos/padrao.jpg"
                                    alt="Logo do cliente"
                                    className="w-60 h-40 rounded"
                                />
                            </>
                        ) : (
                            <img
                                src={logotipo || "https://www.inbravisa.com.br/controle/bemvindo/gerar_certificado/Fotos/padrao.jpg"}
                                alt="Logo do cliente"
                                className="w-60 h-40 rounded"
                            />
                        )}
                        <button
                            className="bg-sky-700 text-white mt-4 px-4 py-2 rounded hover:bg-sky-800 transition"
                            onClick={() => setShowModal(true)}
                        >
                            {logoexist === 'sim' ? 'Trocar Imagem' : 'Adicionar Imagem'}
                        </button>
                    </div>

                    {/* Dados do cliente */}
                    <div className="col-span-2">
                        {dadosCliente ? (
                            <div className="space-y-4">
                                {[
                                    { label: "Nome do Cliente", value: dadosCliente["Razão Social"] || "Não informado" },
                                    { label: "Email", value: dadosCliente.Email || "Não informado" },
                                    { label: "Telefone", value: dadosCliente.Telefone || "Não informado" },
                                    {
                                        label: "Endereço",
                                        value:
                                            `${dadosCliente["Endereço Completo"]},  ${dadosCliente.Complemento}` ||
                                            "Não informado",
                                    },
                                    {
                                        label: "Cidade",
                                        value: `${dadosCliente.Cidade}-${dadosCliente.UF}` || "Não informado",
                                    },
                                    {
                                        label: "Data da Proposta",
                                        value: dadosCliente.createdAt
                                            ? new Date(dadosCliente.createdAt).toLocaleDateString("pt-BR") + ' - ' +
                                            dadosCliente.id_Proposta
                                            : "Não informado",
                                    },
                                ].map(({ label, value }, idx) => (
                                    <div key={idx} className="flex items-center">
                                        <strong className="w-1/3 text-gray-600">{label}:</strong>
                                        <span className="w-2/3 text-gray-900">{value}</span>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <p className="text-red-500 text-center">Não foi possível carregar os dados do cliente.</p>
                        )}
                    </div>
                </div>

                {/* Complemento da ficha */}
                <div className="mt-10">
                    <div className="grid gap-4 md:grid-cols-1">
                        <div className="p-4 bg-gray-50 border rounded-lg shadow-sm text-center">
                            <div className="mt-10">

                                <div className="flex justify-end">
                                    <button
                                        className="bg-sky-700 text-white px-4 py-2 rounded hover:bg-sky-800 transition"
                                        onClick={() => setShowDocModal(true)}
                                    >
                                        Adicionar Documento
                                    </button>
                                </div>

                                <div className="mt-6">
                                    <h3 className="text-4xl font-medium mb-4">Lista de Documentos</h3>
                                    {documents.length > 0 ? (
                                        <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
                                            {documents.map((doc, index) => (
                                                <li
                                                    key={index}
                                                    className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 ease-in-out transform hover:scale-105"
                                                >
                                                    <div >
                                                        <p className="text-gray-800 font-medium text-xl mb-2">{doc.NomeDocumento}</p>
                                                        <p className="text-sm text-gray-600 mb-2">
                                                            Número: {doc.NumeroDocumento}
                                                        </p>
                                                        <p className={`text-sm ${validadeClass(doc.Validade)} mb-2`}>
                                                            Validade: {new Date(doc.Validade).toLocaleDateString("pt-BR")}
                                                        </p>
                                                        <p className="text-sm text-gray-600 mb-4">
                                                            Situação: {doc.Situacao}
                                                        </p>
                                                    </div>
                                                    <div className="flex justify-between items-center">
                                                        <a
                                                            href={`https://www.inbravisa.com.br${doc.caminho}`}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            className="text-sky-600 hover:underline text-sm"
                                                        >
                                                            Baixar
                                                        </a>
                                                        <button
                                                            onClick={() => handleDeleteDocument(doc.id)}
                                                            className="text-red-600 hover:text-red-800 text-sm"
                                                        >
                                                            <i className="fas fa-trash-alt"></i> Deletar
                                                        </button>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    ) : (
                                        <p className="text-gray-500">Nenhum documento encontrado.</p>
                                    )}
                                </div>




                            </div>
                        </div>

                    </div>


                    <div className="p-4 bg-gray-50 border rounded-lg shadow-sm text-center mt-5">
                        <h3 className="font-medium text-gray-700 text-4xl mb-5 border-b">Serviços</h3>
                        {servicos.length > 0 ? (
                            <ul className="space-y-4">
                                {servicos.map((doc, index) => (
                                    <li key={index} className="p-4 bg-gray-100 rounded shadow-md flex justify-between items-center">
                                        <div className="justify-start">
                                            <p className="text-gray-800 font-medium text-left mb-5 text-lg">{doc.descricao_Servico}</p>
                                            <div className="flex justify-start">
                                                <p className="text-sm text-gray-600 items-start">
                                                    Andamento:
                                                    <span
                                                        className={` ml-2 p-2
                                                        text-gray-800 
                                                        ${doc.andamento === 'Iniciado' ? 'bg-yellow-100' : ''}
                                                        ${doc.andamento === 'Pendente' ? 'bg-yellow-100' : ''}
                                                        ${doc.andamento === 'Aguardando Documentação' ? 'bg-blue-100' : ''}
                                                        ${doc.andamento === 'Finalizado / Deferido' ? 'bg-green-100' : ''}
                                                        p-1 rounded
                                                    `}
                                                    >
                                                        {doc.andamento}
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="flex justify-end w-1/2">
                                            <span
                                                className="text-blue-600 cursor-pointer"
                                                onClick={() => openAndamentoModal(doc)} // Abre o modal de alteração de andamento
                                            >
                                                Alterar Andamento
                                            </span>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <p className="text-gray-500">Nenhum documento encontrado.</p>
                        )}
                    </div>




                </div>
            </div>

            {showAndamentoModal && selectedServico && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="bg-white p-6 rounded shadow-lg w-1/3">
                        <h2 className="text-xl font-semibold mb-4">Alterar Andamento</h2>
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                handleUpdateAndamento();
                            }}
                        >
                            <div className="mb-4">
                                <label className="block text-gray-700 font-medium mb-2">
                                    Andamento
                                </label>
                                <select
                                    value={newAndamento}
                                    onChange={(e) => setNewAndamento(e.target.value)}
                                    className="w-full p-2 border rounded"
                                    required
                                >
                                    <option value="Iniciado">Iniciado</option>
                                    <option value="Aguardando Documentação">Aguardando Documentação</option>
                                    <option value="Finalizado / Deferido">Finalizado / Deferido</option>
                                </select>
                            </div>
                            <div className="flex justify-end space-x-4">
                                <button
                                    type="button"
                                    className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
                                    onClick={() => setShowAndamentoModal(false)} // Fecha o modal sem salvar
                                >
                                    Cancelar
                                </button>
                                <button
                                    type="submit"
                                    className="px-4 py-2 rounded text-white bg-sky-700 hover:bg-sky-800"
                                >
                                    Salvar
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}

            {
                showModal && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                        <div className="bg-white p-6 rounded shadow-lg w-1/3">
                            <h2 className="text-xl font-semibold mb-4">Upload de Imagem</h2>
                            <div
                                {...getRootProps({
                                    className:
                                        "border-2 border-dashed p-6 rounded text-center cursor-pointer",
                                })}
                            >
                                <input {...getInputProps()} />
                                <p>Arraste e solte um arquivo aqui ou clique para selecionar</p>
                            </div>
                            {selectedFile && (
                                <p className="mt-4 text-sm text-gray-600">
                                    Arquivo selecionado: {selectedFile.name}
                                </p>
                            )}
                            <div className="mt-4 flex justify-end space-x-4">
                                <button
                                    className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
                                    onClick={() => setShowModal(false)}
                                >
                                    Cancelar
                                </button>
                                <button
                                    className={`px-4 py-2 rounded text-white ${uploading ? "bg-gray-400" : "bg-sky-700 hover:bg-sky-800"
                                        }`}
                                    onClick={handleUpload}
                                    disabled={uploading}
                                >
                                    {uploading ? "Carregando..." : "Upload"}
                                </button>
                            </div>
                        </div>
                    </div>
                )
            }

            {/* Modal para Cadastro de Documentos */}
            {showDocModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="bg-white p-6 rounded shadow-lg w-1/3">
                        <h2 className="text-xl font-semibold mb-4">Adicionar Documento</h2>
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                const formData = new FormData(e.target);
                                handleUploadDoc(formData);
                            }}
                        >
                            {/* Campo oculto para id_proposta */}
                            <div className="mb-4">

                                <input
                                    type="hidden"
                                    name="id_proposta"
                                    className="w-full p-2 border rounded bg-slate-400"
                                    value={localStorage.getItem("IdProposta")}
                                    readOnly
                                />
                            </div>
                            <div className="mb-4">

                                <input
                                    type="hidden"
                                    name="cnpj"
                                    className="w-full p-2 border rounded bg-slate-400"
                                    placeholder="Digite o CNPJ"
                                    value={dadosCliente.Numero}
                                    required
                                    readOnly
                                />
                            </div>
                            <div className="mb-4">

                                <input
                                    type="hidden"
                                    name="RazaoSocial"
                                    className="w-full p-2 border rounded bg-slate-400 "
                                    placeholder="Digite a Razão Social"
                                    value={dadosCliente["Razão Social"]}
                                    required
                                    readOnly
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 font-medium mb-2">
                                    Validade
                                </label>
                                <input
                                    type="date"
                                    name="Validade"
                                    className="w-full p-2 border rounded"
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 font-medium mb-2">
                                    Nome do Documento
                                </label>
                                <input
                                    type="text"
                                    name="NomeDocumento"
                                    className="w-full p-2 border rounded"
                                    placeholder="Digite o nome do documento"
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 font-medium mb-2">
                                    Número do Documento
                                </label>
                                <input
                                    type="text"
                                    name="NumeroDocumento"
                                    className="w-full p-2 border rounded"
                                    placeholder="Digite o número do documento"
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 font-medium mb-2">
                                    Situação
                                </label>
                                <select
                                    name="Situacao"
                                    className="w-full p-2 border rounded"
                                    required
                                >
                                    <option value="">Selecione a Situação</option>
                                    <option value="1">Protocolo</option>
                                    <option value="2">Definitivo</option>
                                </select>
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 font-medium mb-2">
                                    Arquivo
                                </label>
                                <input
                                    type="file"
                                    name="file"
                                    className="w-full p-2 border rounded"
                                    required
                                />
                            </div>
                            <div className="flex justify-end space-x-4">
                                <button
                                    type="button"
                                    className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
                                    onClick={() => setShowDocModal(false)}
                                >
                                    Cancelar
                                </button>
                                <button
                                    type="submit"
                                    className={`px-4 py-2 rounded text-white ${uploading ? "bg-gray-400" : "bg-sky-700 hover:bg-sky-800"
                                        }`}
                                    disabled={uploading}
                                >
                                    {uploading ? "Carregando..." : "Enviar"}
                                </button>

                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>

    );
};

export default VerFichaCliente;



