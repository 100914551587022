import React from "react";

import MenuLateral from "../../../../Components-Adm/MenuLateral";
import Adicionar from "../../../../Components-Adm/AdicionarProposta";



export const CriarPropostas = ()=>{
    return(
        <div className="flex">
        <MenuLateral />
        <Adicionar />        
        </div>
    )
}