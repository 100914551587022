import { useState, React } from "react";
import api from "../../config/apiAdm";
import { FiEye,FiEyeOff  } from "react-icons/fi";
import { useNavigate, Link, useParams } from 'react-router-dom';


export const Adm = () => {
    const [formData, setFormData] = useState({ Usuario: "", Senha: "" });
    const [message, setMessage] = useState("");
    const [showPassword, setShowPassword] = useState(false);
     const navigate = useNavigate();

    // Função para capturar mudanças nos campos
    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    // Função para envio do formulário
    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('dados enviados: ' + formData)
        try {
            const response = await api.post("/login", formData);

            if (!response.data.erro) {
                setMessage("Login realizado com sucesso!");
                console.log(response.data.Nome)
                localStorage.setItem('NomeUsuario', response.data.Nome )
                localStorage.setItem('NivelAcesso', response.data.NivelAcesso )
                localStorage.setItem('UserToken', response.data.token )
                localStorage.setItem('idusuario', response.data.id )
                // Aqui você pode redirecionar o usuário ou salvar informações no estado
                navigate('/AreaAdm/Agenda');
            } else {
                setMessage("Erro: " + response.data.mensagem);
            }
        } catch (error) {
            console.error(error);
            setMessage("Erro ao realizar login. Verifique suas credenciais.");
        }
    };
    return (
        <div className="flex min-h-screen bg-gradient-to-r from-gray-800 via-gray-900 to-black text-white">
            {/* Lado esquerdo: Imagem ou marca */}
            <div className="hidden lg:flex lg:w-1/2 flex-col justify-center items-center">
                <div className="lg:bg-white lg:p-5 rounded-lg">
                    <img
                        className="w-48 h-48 "
                        src="http://www.inbravisa.com.br/imagens/logo%20INBRAVISA_FB%20(13).jpg"
                        alt="Logo Inbravisa"
                    />
                </div>
                <h1 className="text-white text-2xl font-semibold mt-4">
                    Bem-vindo ao INBRAVISA
                </h1>
                <p className="text-gray-300 mt-2 text-center">
                    Acesse a plataforma de Gestão do INBRAVISA.
                </p>
            </div>


            {/* Lado direito: Formulário de login */}
            <div className="flex-1 flex flex-col justify-center items-center py-12 px-6 sm:px-12 lg:w-1/2">
                <div className=" lg:hidden">
                    <h1 className="text-4xl mb-5"> Acessar o Sistema INBRAVISA</h1>
                </div>
                <div className="bg-white py-8 px-6 shadow rounded-lg sm:px-10 w-full max-w-md text-gray-900">
                    <h2 className="text-2xl font-bold text-center">
                        Entrar na conta
                    </h2>
                    <p className="mt-2 text-sm text-gray-600 text-center">
                        Por favor, insira suas credenciais para acessar.
                    </p>

                    <form className="mt-6 space-y-6" onSubmit={handleSubmit}>
                        {/* Feedback de mensagens */}
                        {message && (
                            <div
                                className={`p-3 rounded ${message.includes("sucesso") ? "bg-green-100 text-green-700" : "bg-red-100 text-red-700"
                                    }`}
                            >
                                {message}
                            </div>
                        )}

                        {/* Campo Usuário */}
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                Usuário
                            </label>
                            <div className="mt-1">
                                <input
                                    name="Usuario"
                                    type="text"
                                    value={formData.email}
                                    onChange={handleChange}
                                    className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    placeholder="Digite seu email"
                                />
                            </div>
                        </div>

                        {/* Campo Senha */}
                        <div>
                            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                Senha
                            </label>
                            <div className="mt-1 relative">
                                <input
                                    name="Senha"
                                    type={showPassword ? "text" : "password"} // Alterna entre "password" e "text"
                                    value={formData.password}
                                    onChange={handleChange}
                                    className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    placeholder="Digite sua senha"
                                />
                                <button
                                    type="button"
                                    onClick={() => setShowPassword(!showPassword)} // Alterna o estado
                                    className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-500 hover:text-indigo-500 focus:outline-none"
                                >
                                    {showPassword ? (
                                        <FiEye/>
                                    ) : (
                                      <FiEyeOff/>
                                    )}
                                </button>
                            </div>

                        </div>

                        {/* Botão Entrar */}
                        <div>
                            <button
                                type="submit"
                                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                                Entrar
                            </button>
                        </div>
                    </form>

                    <p className="mt-6 text-sm text-gray-600 text-center">
                        Esqueceu sua senha?{" "}
                        <a href="#" className="text-indigo-500 hover:underline">
                            Clique aqui
                        </a>
                    </p>
                </div>
            </div>
        </div>



    )
}