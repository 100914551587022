import React, { useEffect, useState } from "react";
import api from "../../config/apiAdm";

const VerFinanca = () => {
  const [registros, setRegistros] = useState([]);
  const [registrosFiltrados, setRegistrosFiltrados] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [mesAtual, setMesAtual] = useState(new Date());

  useEffect(() => {
    const fetchDados = async () => {
      try {
        const response = await api.get("/verfluxocaixa");
        setRegistros(response.data);
      } catch (error) {
        setError("Erro ao buscar dados do fluxo de caixa.");
        console.error("Erro ao buscar registros do fluxo de caixa:", error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchDados();
  }, []);

  useEffect(() => {
    filtrarPorMes(mesAtual);
  }, [mesAtual, registros]);

  const filtrarPorMes = (data) => {
    const mes = data.getMonth();
    const ano = data.getFullYear();

    const registrosFiltrados = registros.filter((registro) => {
      const dataRegistro = new Date(registro.data_prevista);
      return dataRegistro.getMonth() === mes && dataRegistro.getFullYear() === ano;
    });

    setRegistrosFiltrados(registrosFiltrados);
  };

  const mudarMes = (direcao) => {
    const novaData = new Date(mesAtual);
    novaData.setMonth(mesAtual.getMonth() + direcao);
    setMesAtual(novaData);
  };

  const atualizarPagamento = async (id) => {
    try {
      const response = await api.put(`/atualizarpagamento/${id}`, {
        pagamento_realizado: "Sim",
      });
      if (response.status === 200) {
        setRegistros((prevRegistros) =>
          prevRegistros.map((registro) =>
            registro.id === id
              ? {
                  ...registro,
                  pagamento_realizado: "Sim",
                  updatedAt: new Date().toISOString(),
                }
              : registro
          )
        );
      }
    } catch (error) {
      console.error("Erro ao atualizar pagamento:", error.message);
      alert("Erro ao atualizar pagamento. Tente novamente.");
    }
  };

  const calcularTotais = () => {
    const totalEsperado = registrosFiltrados.reduce(
      (acc, registro) => acc + (registro.valor_pagar || 0),
      0
    );

    const totalConfirmado = registrosFiltrados.reduce(
      (acc, registro) =>
        registro.pagamento_realizado === "Sim" ? acc + (registro.valor_pagar || 0) : acc,
      0
    );

    return { totalEsperado, totalConfirmado };
  };

  const { totalEsperado, totalConfirmado } = calcularTotais();

  if (loading) return <p>Carregando...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="p-5">
      <h2 className="text-xl font-bold mb-4">Tabela Financeira</h2>
      <div className="mb-4 flex items-center gap-4">
        <button
          onClick={() => mudarMes(-1)}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          Mês Anterior
        </button>
        <span className="text-lg font-semibold">
          {mesAtual.toLocaleDateString("pt-BR", { month: "long", year: "numeric" })}
        </span>
        <button
          onClick={() => mudarMes(1)}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          Próximo Mês
        </button>
      </div>
      <table className="table-auto w-full border-collapse border border-gray-300">
        <thead className="bg-gray-100">
          <tr>
            <th className="border border-gray-300 p-2">Razão Social</th>
            <th className="border border-gray-300 p-2">Valor a Pagar</th>
            <th className="border border-gray-300 p-2">Data Prevista</th>
            <th className="border border-gray-300 p-2">Pagamento Realizado</th>
            <th className="border border-gray-300 p-2">Conta</th>
            <th className="border border-gray-300 p-2">Data do Pagamento</th>
            <th className="border border-gray-300 p-2">Ação</th>
          </tr>
        </thead>
        <tbody>
          {registrosFiltrados.map((registro, index) => (
            <tr key={index} className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}>
              <td className="border border-gray-300 p-2">{registro.razao_Social}</td>
              <td className="border border-gray-300 p-2">{Number(registro.valor_pagar).toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  })} </td>
              <td className="border border-gray-300 p-2">
                {new Date(registro.data_prevista).toLocaleDateString("pt-BR")}
              </td>
              <td className="border border-gray-300 p-2">{registro.pagamento_realizado}</td>
              <td className="border border-gray-300 p-2">{registro.conta}</td>
              <td className="border border-gray-300 p-2">
                {registro.pagamento_realizado === "Sim"
                  ? new Date(registro.updatedAt).toLocaleDateString("pt-BR")
                  : "—"}
              </td>
              <td className="border border-gray-300 p-2 text-center">
                {registro.pagamento_realizado === "Nao" ? (
                  <button
                    onClick={() => atualizarPagamento(registro.id)}
                    className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
                  >
                    Adicionar Pagamento
                  </button>
                ) : (
                  "Pagamento Realizado"
                )}
              </td>
            </tr>
          ))}
          {registrosFiltrados.length === 0 && (
            <tr>
              <td colSpan="7" className="text-center border border-gray-300 p-4">
                Nenhum registro encontrado para o mês selecionado.
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <div className="mt-4">
        <p className="text-lg font-semibold">
          Total Esperado: <span className="text-blue-600">{Number(totalEsperado).toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  })} </span>
          
        </p>
        <p className="text-lg font-semibold">
          Total Confirmado: <span className="text-green-600">R${Number(totalConfirmado).toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  })} </span>
        </p>
      </div>
    </div>
  );
};

export default VerFinanca;
