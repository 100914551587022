import { useEffect, useState } from "react";
import api from "../../config/apiAdm";
import { useNavigate } from 'react-router-dom';

const VerCliente = () => {
  const navigate = useNavigate();
  const [propostas, setPropostas] = useState([]);
  const [filtro, setFiltro] = useState("");

  useEffect(() => {
    const fetchPropostas = async () => {
      try {
        const response = await api.get("/verClientes");
        if (!response.data.erro) {
          setPropostas(response.data.dados);
        } else {
          alert("Erro ao carregar propostas: " + response.data.mensagem);
        }
      } catch (error) {
        console.error("Erro ao carregar propostas:", error);
        alert("Erro ao carregar propostas.");
      }
    };

    fetchPropostas();
  }, []);

  const handleFiltroChange = (e) => {
    setFiltro(e.target.value.toLowerCase());
  };

  const propostasFiltradas = propostas.filter((proposta) =>
    proposta["Razão Social"]?.toLowerCase().includes(filtro)
  );

  const irFicha = (id_Proposta) => {
    localStorage.setItem('IdProposta',id_Proposta)
    console.log('id proposta clicado é:: ' + id_Proposta)
    navigate(`/AreaAdm/FichaCliente`);
  }

  return (
    <div className="flex-1 p-8 bg-gray-100">
      <h1 className="text-2xl font-bold mb-4">Visualizar Cliente</h1>

      <div className="mb-4">
        <input
          type="text"
          className="border border-gray-300 p-2 w-full md:w-1/2 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Filtrar por Razão Social"
          value={filtro}
          onChange={handleFiltroChange}
        />
      </div>

      <div className="bg-white p-4 rounded shadow">
        <table className="w-full border-collapse border border-gray-300">
          <thead>
            <tr className="bg-gray-200">
              <th className="border border-gray-300 p-2">ID</th>
              <th className="border border-gray-300 p-2">Razão Social</th>
              <th className="border border-gray-300 p-2">Ver ficha</th>
            </tr>
          </thead>
          <tbody>
            {propostasFiltradas.length > 0 ? (
              propostasFiltradas.map((proposta) => (
                <tr key={proposta.id} className="text-center hover:bg-slate-100">
                  <td className="border border-gray-300 p-2">{proposta.id_Proposta}</td>
                  <td className="border border-gray-300 p-2">{proposta["Razão Social"]}</td>
                  <td className="border border-gray-300 p-2">
                    <button
                      className="bg-sky-600 text-white hover:bg-sky-900 rounded-xl lg:p-2 p-1"
                      onClick={() => irFicha(proposta.id_Proposta)}
                    >
                      Ver Ficha
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td className="border border-gray-300 p-2 text-center" colSpan="3">
                  Nenhuma proposta encontrada.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default VerCliente;
