import { useEffect, useState } from "react";
import api from '../../config/apiAdm';
import { useNavigate } from 'react-router-dom';






const SelecionarServ = () => {
  const navigate = useNavigate();
  // Estado para armazenar os serviços recebidos da API
  const [servicos, setServicos] = useState([]);
  const [selectedServicos, setSelectedServicos] = useState([]);
  const [outroServico, setOutroServico] = useState("");
  const [isOutroChecked, setIsOutroChecked] = useState(false);

  // Fetch de dados dos serviços
  useEffect(() => {
    const fetchServicos = async () => {
      try {
        const response = await api.get("/verServicosPrestados");
        const data = response.data; // Usando os dados já retornados pelo axios
        console.log(data);

        if (!data.erro) {
          setServicos(data.dados);  // Armazenando os dados recebidos
        } else {
          console.error("Erro ao carregar os serviços.");
        }
      } catch (error) {
        console.error("Erro na requisição:", error);
      }
    };

    fetchServicos();
  }, []);  // A dependência vazia faz o fetch ser executado apenas uma vez após o componente montar

  // Função para lidar com a mudança nos checkboxes
  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;

    if (checked) {
      // Adiciona o serviço ao estado se ele for selecionado
      setSelectedServicos((prevSelected) => [...prevSelected, value]);
    } else {
      // Remove o serviço do estado se ele for desmarcado
      setSelectedServicos((prevSelected) => prevSelected.filter((servico) => servico !== value));
    }
  };

  // Função para lidar com o campo "Outros"
  const handleOutroChange = (e) => {
    setOutroServico(e.target.value);
  };

  const handleOutroCheckboxChange = (e) => {
    const { checked } = e.target;
    setIsOutroChecked(checked);

    if (!checked) {
      setOutroServico("");
    }
  };

  // Agrupar os serviços pela classe e ordenar os serviços por nome dentro de cada classe
  const groupedServices = servicos.reduce((acc, servico) => {
    // Verifica se a classe já existe no acumulador
    if (!acc[servico.classe]) {
      acc[servico.classe] = [];
    }
    // Adiciona o serviço ao grupo correspondente à classe
    acc[servico.classe].push(servico);
    return acc;
  }, {});

  // Ordena os serviços dentro de cada classe pela descrição
  for (const classe in groupedServices) {
    groupedServices[classe].sort((a, b) => a.descricao_Servico.localeCompare(b.descricao_Servico));
  }

  // Mapeia as classes para seus nomes amigáveis
  const classNames = {
    1: "Formação de processo",
    2: "Assessoria",
    3: "Serviços Especiais"
  };

  const handleSubmit = async () => {
    const idProposta = localStorage.getItem('IdProposta');
    if (!idProposta) {
      console.error("ID da proposta não encontrado.");
      return;
    }

    const servicosPayload = selectedServicos.map((idServico) => {
      const servico = servicos.find((s) => s.id.toString() === idServico);
      return {
        idServico: servico.id,
        descricao_Servico: servico.descricao_Servico,
        andamento: "Pendente",
        observacao: "",
      };
    });

    // Adicionar o serviço "Outros" se selecionado
    if (isOutroChecked && outroServico.trim() !== "") {
      servicosPayload.push({
        idServico: 68, // Indica que é um serviço personalizado
        descricao_Servico: outroServico.trim(),
        andamento: "Pendente",
        observacao: "",
      });
    }

    try {
      const response = await api.post("/cadastrarServ", {
        idProposta,
        servicos: servicosPayload,
      });

      if (!response.data.erro) {
        alert("Serviços cadastrados com sucesso!");
        navigate('/AreaAdm/Propostas/VerPropostas');
      } else {
        alert("Erro ao cadastrar serviços: " + response.data.mensagem);
      }
    } catch (error) {
      console.error("Erro ao enviar os serviços:", error);
    }
  };

  return (
    <div className="flex-1 p-8 bg-gray-50">
      <h1 className="text-4xl font-extrabold text-center mb-8 text-gray-800">
        Escolha os serviços para o cliente
      </h1>

      <div className="flex justify-center mb-8">
        <button
          className="px-6 py-3 rounded-lg bg-emerald-500 text-white font-medium text-lg shadow-md hover:bg-emerald-600 transition-all"
          onClick={handleSubmit}
        >
          Cadastrar
        </button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {Object.keys(groupedServices).map((classe) => (
          <div
            key={classe}
            className="bg-white p-6 rounded-xl shadow-md hover:shadow-xl transition-all border border-gray-200"
          >
            <div className="mb-6">
              <h2
                className="text-2xl font-semibold text-gray-800 border-b-2 border-blue-500 pb-2 mb-4"
              >
                {classNames[classe] || `Classe ${classe}`}
              </h2>
            </div>

            <div className="space-y-6">
              {groupedServices[classe].map((servico) => (
                <div
                  key={servico.id}
                  className="flex items-center gap-4 bg-gray-50 p-4 rounded-md shadow-sm hover:bg-gray-100"
                >
                  <input
                    type="checkbox"
                    id={`servico-${servico.id}`}
                    value={servico.id}
                    checked={selectedServicos.includes(servico.id.toString())}
                    onChange={handleCheckboxChange}
                    className="h-5 w-5 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                  />
                  <label
                    htmlFor={`servico-${servico.id}`}
                    className="text-lg font-medium text-gray-700 hover:text-gray-900 cursor-pointer"
                  >
                    {servico.descricao_Servico}
                  </label>
                </div>
              ))}

              {classe === "3" && (
                <div className="mt-6">
                  <div className="flex items-center gap-4 bg-gray-50 p-4 rounded-md shadow-sm hover:bg-gray-100">
                    <input
                      type="checkbox"
                      id="outro-servico"
                      checked={isOutroChecked}
                      onChange={handleOutroCheckboxChange}
                      className="h-5 w-5 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                    />
                    <label
                      htmlFor="outro-servico"
                      className="text-lg font-medium text-gray-700 hover:text-gray-900 cursor-pointer"
                    >
                      Digitar Serviço
                    </label>
                  </div>

                  {isOutroChecked && (
                    <input
                      type="text"
                      placeholder="Digite o serviço específico"
                      value={outroServico}
                      onChange={handleOutroChange}
                      className="mt-4 p-3 w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>

    </div>
  );

};

export default SelecionarServ;

