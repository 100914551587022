import React from "react";

import MenuLateral from "../../../Components-Adm/MenuLateral";
import CompKanban from "../../../Components-Adm/CompKanban";




export const Kanban = ()=>{
    return(
        <div className="flex">
        <MenuLateral />
        <div className=" w-full pl-28 pr-28">
           <h1>Kanban</h1>
           <CompKanban />  
        </div>
              
        </div>
    )
}