import React from "react";

import MenuLateral from "../../../Components-Adm/MenuLateral"
import Dash from '../../../Components-Adm/Dashboards';



export const Dashboard = ()=>{
    return(
        <div className="flex">
        <MenuLateral />
        <Dash />

        </div>
    )
}