import React from "react";

import MenuLateral from "../../../../Components-Adm/MenuLateral";
import SelecionarServ from "../../../../Components-Adm/SelecionarServ";



export const SelecionarServico = ()=>{
    return(
        <div className="flex">
        <MenuLateral />
        <SelecionarServ />        
        </div>
    )
}