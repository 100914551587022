import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import api from "../../config/apiAdm";

const initialData = {
  tasks: {
    "task-1": { id: "task-1", content: "Estudar React" },
    "task-2": { id: "task-2", content: "Criar Projeto Kanban" },
    "task-3": { id: "task-3", content: "Testar aplicação" },
    "task-4": { id: "task-4", content: "Aplicar" },
    "task-5": { id: "task-5", content: "Deploy" },
    "task-6": { id: "task-6", content: "Arrumar" },
  },
  columns: {
    "column-1": {
      id: "column-1",
      title: "A Fazer",
      taskIds: ["task-1", "task-2", "task-3","task-4", "task-5", "task-6"],
    },
    "column-2": {
      id: "column-2",
      title: "Fazendo",
      taskIds: [],
    },
    "column-3": {
      id: "column-3",
      title: "Feito",
      taskIds: [],
    },
  },
  columnOrder: ["column-1", "column-2", "column-3"],
};



const CompKanban = () => {
  const [data, setData] = useState(initialData);

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) return;

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const startColumn = data.columns[source.droppableId];
    const endColumn = data.columns[destination.droppableId];

    if (startColumn === endColumn) {
      const newTaskIds = Array.from(startColumn.taskIds);
      newTaskIds.splice(source.index, 1);
      newTaskIds.splice(destination.index, 0, draggableId);

      const newColumn = {
        ...startColumn,
        taskIds: newTaskIds,
      };

      setData({
        ...data,
        columns: {
          ...data.columns,
          [newColumn.id]: newColumn,
        },
      });
      return;
    }

    // Moving between columns
    const startTaskIds = Array.from(startColumn.taskIds);
    startTaskIds.splice(source.index, 1);
    const newStart = {
      ...startColumn,
      taskIds: startTaskIds,
    };

    const endTaskIds = Array.from(endColumn.taskIds);
    endTaskIds.splice(destination.index, 0, draggableId);
    const newEnd = {
      ...endColumn,
      taskIds: endTaskIds,
    };

    setData({
      ...data,
      columns: {
        ...data.columns,
        [newStart.id]: newStart,
        [newEnd.id]: newEnd,
      },
    });
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        {data.columnOrder.map((columnId) => {
          const column = data.columns[columnId];
          const tasks = column.taskIds.map((taskId) => data.tasks[taskId]);

          return (
            <Droppable key={column.id} droppableId={column.id}>
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={{
                    margin: "8px",
                    border: "1px solid lightgray",
                    borderRadius: "4px",
                    width: "250px",
                    backgroundColor: "#CCC",
                  }}
                >
                  <h3 style={{ padding: "8px" }}>{column.title}</h3>
                  {tasks.map((task, index) => (
                    <Draggable
                      key={task.id}
                      draggableId={task.id}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            padding: "16px",
                            margin: "8px",
                            backgroundColor: "white",
                            borderRadius: "4px",
                            boxShadow: "0 1px 3px rgba(0,0,0,0.2)",
                            ...provided.draggableProps.style,
                          }}
                        >
                          {task.content}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          );
        })}
      </div>
    </DragDropContext>
  );
};

export default CompKanban;