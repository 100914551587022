import { useEffect, useState } from "react";
import { FiHome, FiUsers, FiSettings, FiFileText, FiDollarSign, FiCalendar, FiUserPlus, FiLogOut } from "react-icons/fi";
import { ImBubbles2 } from "react-icons/im";
import { Link, useNavigate } from "react-router-dom";


const MenuLateral = () => {
  const navigate = useNavigate();
  const [isExpanded, setIsExpanded] = useState(true);
  const [activeSubmenu, setActiveSubmenu] = useState(null);
  const [logado, setLogado] = useState(localStorage.getItem('UserToken'));


  const toggleSidebar = () => setIsExpanded(!isExpanded);
  const toggleSubmenu = (menu) => setActiveSubmenu(activeSubmenu === menu ? null : menu);

  const funcSair = () => {
    localStorage.removeItem('UserToken');
    localStorage.removeItem('NomeUsuario');
    localStorage.removeItem('NivelAcesso');
    navigate('/adm')
  }

  useEffect(() => {
    if (localStorage.getItem('UserToken') != null) {
      // Insira aqui o que deve acontecer se o usuário estiver logado      
    } else {
      navigate('/adm'); // Redireciona para a rota '/adm' se não estiver logado
    }
  }, []);

  return (


    <div

      className={`min-h-screen transition-all duration-300 bg-gray-800 text-white ${isExpanded ? "w-64" : "w-24"
        }`}
    >



      {/* Cabeçalho do menu */}
      <div className="flex items-center justify-between p-4">
        <span className={`text-lg font-bold ${!isExpanded && "hidden"}`}>Gestão - {localStorage.getItem("NomeUsuario")}</span>
        <button
          onClick={toggleSidebar}
          className="p-2 rounded bg-sky-600 text-white hover:bg-blue-500"
        >
          {isExpanded ? "⮜" : "⮞"}
        </button>
      </div>

      {/* Navegação */}
      <nav className="mt-4">
        <ul>
          <Link to="/AreaAdm/Dashboard">
            <li className="p-4 hover:bg-blue-700 cursor-pointer flex items-center gap-4">
              <FiHome size={20} />
              {isExpanded && <span>Dashboard</span>}
            </li>
          </Link>

          <li>
            <div
              onClick={() => toggleSubmenu("propostas")}
              className="p-4 hover:bg-blue-700 cursor-pointer flex items-center justify-between"
            >
              <div className="flex items-center gap-4">
                <FiFileText size={20} />
                {isExpanded && <span>Propostas</span>}
              </div>
              {isExpanded && <span>{activeSubmenu === "propostas" ? "⮟" : "⮞"}</span>}
            </div>
            {activeSubmenu === "propostas" && (
              <ul className={`${isExpanded ? "ml-6" : "ml-2"} text-sm`}>
                <Link to="/AreaAdm/Propostas/CriarPropostas"><li className="p-2 hover:bg-blue-600 cursor-pointer">Criar Proposta</li></Link>
                <Link to="/AreaAdm/Propostas/VerPropostas"><li className="p-2 hover:bg-blue-600 cursor-pointer">Ver Propostas</li></Link>
              </ul>
            )}
          </li>

          <li>
            <div
              onClick={() => toggleSubmenu("clientes")}
              className="p-4 hover:bg-blue-700 cursor-pointer flex items-center justify-between"
            >
              <div className="flex items-center gap-4">
                <FiUsers size={20} />
                {isExpanded && <span>Clientes</span>}
              </div>
              {isExpanded && <span>{activeSubmenu === "clientes" ? "⮟" : "⮞"}</span>}
            </div>
            {activeSubmenu === "clientes" && (
              <ul className={`${isExpanded ? "ml-6" : "ml-2"} text-sm`}>
                {/*<Link to="/AreaAdm/Clientes/Adicionar"><li className="p-2 hover:bg-blue-600 cursor-pointer">Adicionar Cliente</li></Link> */}
                <Link to="/AreaAdm/Clientes/Visualizar"><li className="p-2 hover:bg-blue-600 cursor-pointer">Lista de Clientes</li> </Link>
              </ul>
            )}
          </li>
          {localStorage.getItem('NivelAcesso') === '1' ? (
            <li>
              <div
                onClick={() => toggleSubmenu("financeiro")}
                className="p-4 hover:bg-blue-700 cursor-pointer flex items-center justify-between"
              >
                <div className="flex items-center gap-4">
                  <FiDollarSign size={20} />
                  {isExpanded && <span>Financeiro</span>}
                </div>
                {isExpanded && <span>{activeSubmenu === "financeiro" ? "⮟" : "⮞"}</span>}
              </div>
              {activeSubmenu === "financeiro" && (
                <ul className={`${isExpanded ? "ml-6" : "ml-2"} text-sm`}>
                  <Link to="/AreaAdm/Financeiro/CalendarioFinanceiro"><li className="p-2 hover:bg-blue-600 cursor-pointer">Calendario financeiro</li></Link>
                  <Link to="/AreaAdm/Financeiro/Tabela"><li className="p-2 hover:bg-blue-600 cursor-pointer">Tabela Financeira</li> </Link>
                </ul>
              )}
            </li>
          ) : ('')}
          {/* <Link to="/AreaAdm/Consulta">
            <li className="p-4 hover:bg-blue-700 cursor-pointer flex items-center gap-4">
              <ImBubbles2 size={20} />
              {isExpanded && <span>Consulta</span>}
            </li>
          </Link> */}

          <Link to="/AreaAdm/Agenda"><li className="p-4 hover:bg-blue-700 cursor-pointer flex items-center gap-4">
            <FiCalendar size={20} />
            {isExpanded && <span>Agenda</span>}
          </li></Link>



          {/* 
        
          <Link to="/AreaAdm/Financeiro">
            <li className="p-4 hover:bg-blue-700 cursor-pointer flex items-center gap-4">
              <FiDollarSign size={20} />
              {isExpanded && <span>Financeiro</span>}
            </li>
          </Link>
          
          */}
          {localStorage.getItem('NivelAcesso') === '1' ? (
            <>
              <Link to="/AreaAdm/Adduser">
                <li className="p-4 hover:bg-blue-700 cursor-pointer flex items-center gap-4">
                  <FiUserPlus size={20} />
                  {isExpanded && <span>Adicionar Usuário</span>}
                </li>
              </Link>
            </>
          ) : ('')}

          {/*
          <Link to="">
            <li className="p-4 hover:bg-blue-700 cursor-pointer flex items-center gap-4">
              <FiSettings size={20} />
              {isExpanded && <span>Configurações</span>}
            </li>
          </Link>
          */}
          <buttor onClick={funcSair}>
            <li className="p-4 hover:bg-red-700 cursor-pointer flex items-center gap-4">

              <FiLogOut size={20} />

              {isExpanded && <span>Sair</span>}

            </li>
          </buttor>

        </ul>
      </nav>




    </div>
  );
};

export default MenuLateral;
