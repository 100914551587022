import React from "react";

import MenuLateral from "../../../../Components-Adm/MenuLateral";
import Visualizar from "../../../../Components-Adm/VerPropostas";



export const VerPropostas = ()=>{
    return(
        <div className="flex">
        <MenuLateral />
        <Visualizar />        
        </div>
    )
}