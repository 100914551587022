import React from "react";

import MenuLateral from "../../../Components-Adm/MenuLateral"
import VerFichaCliente from '../../../Components-Adm/VerFichaCliente';



export const FichaCliente = ()=>{
    return(
        <div className="flex">
        <MenuLateral />
        <VerFichaCliente />

        </div>
    )
}