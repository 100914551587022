import React from "react";


import MenuLateral from "../../../../Components-Adm/MenuLateral";
import TabFinanca from "../../../../Components-Adm/VerFinanca";



export const Tabela = ()=>{
    return(
        <div className="flex">
        <MenuLateral />
        <TabFinanca />        
        </div>
    )
}