import { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import ptBrLocale from "@fullcalendar/core/locales/pt-br";
import api from "../../config/apiAdm";

const Financeiro = () => {
  const [events, setEvents] = useState([]); // Armazena os eventos do calendário
  const [loading, setLoading] = useState(true); // Controle de carregamento
  const [showView, setShowView] = useState(false); // Controle da exibição do modal
  const [viewEvent, setViewEvent] = useState({}); // Detalhes do evento selecionado

  // Carregar eventos da API ao montar o componente
  useEffect(() => {
    const carregarEventos = async () => {
      try {
        const response = await api.get("/verfluxocaixa"); // Substitua pela URL correta da sua API
        const registros = response.data;

        // Mapeando os dados da API para o formato que o FullCalendar espera
        const eventosConvertidos = registros.map((registro) => ({
          id: registro.id,
          title: registro.razao_Social, // Exibindo a razão social e o ID da proposta
          start: registro.data_prevista, // Data do evento
          extendedProps: {
            id_Proposta: registro.id_Proposta,
            razao_Social: registro.razao_Social,
            valor_pagar: registro.valor_pagar,
            pagamento_realizado: registro.pagamento_realizado,
            conta: registro.conta,
          },
        }));

        setEvents(eventosConvertidos); // Atualiza o estado com os eventos
      } catch (error) {
        console.error("Erro ao carregar dados do fluxo de caixa:", error);
      } finally {
        setLoading(false); // Fim do carregamento
      }
    };

    carregarEventos(); // Chama a função para carregar os eventos
  }, []); // Executa apenas uma vez, quando o componente é montado

  // Lidar com o clique em um evento
  const handleEventClick = (info) => {
    const { extendedProps } = info.event;

    // Define os dados do evento para exibir no modal
    setViewEvent({
      id: info.event.id,
      title: info.event.title,
      start: info.event.start?.toLocaleString(),
      valor_pagar: extendedProps.valor_pagar,
      razao_Social: extendedProps.razao_Social,
      id_Proposta: extendedProps.id_Proposta,
      pagamento_realizado: extendedProps.pagamento_realizado,
      conta: extendedProps.conta,
    });

    setShowView(true); // Exibe o modal
  };

  const confirmarPagamento = async () => {
    try {
      const response = await api.put("/incluirpagamento", {
        id: viewEvent.id,
      });

      if (response.status === 200) {
        alert("Pagamento confirmado com sucesso!");
        setShowView(false); // Fecha o modal após confirmar o pagamento

        // Atualiza os eventos para refletir a alteração
        setEvents((prevEvents) =>
          prevEvents.map((event) =>
            event.id === viewEvent.id
              ? { ...event, extendedProps: { ...event.extendedProps, pagamento_realizado: "Sim" } }
              : event
          )
        );
      } else {
        alert("Erro ao confirmar o pagamento.");
      }
    } catch (error) {
      console.error("Erro ao confirmar pagamento:", error);
      alert("Erro ao confirmar o pagamento.");
    }
  };

  return (
    <div className="container mx-auto mt-6 bg-slate-400 p-5 rounded-lg">
      <h1 className="text-2xl font-bold text-center mb-4">Calendário Financeiro</h1>

      {/* Exibe mensagem de carregamento ou o calendário */}
      {!loading ? (
        <>
          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
            headerToolbar={{
              left: "prev,next today",
              center: "title",
              right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
            }}
            initialView="dayGridMonth" // Exibe o calendário no mês inicial
            events={events} // Eventos carregados da API
            locale={ptBrLocale} // Define o idioma para português
            eventClick={handleEventClick} // Lida com o clique no evento
          />

          {/* Modal para exibir os detalhes do evento */}
          {showView && (
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50">
              <div className="bg-white p-6 rounded-lg w-96">
                <h2 className="text-xl font-bold mb-4">Detalhes do Fluxo de Caixa</h2>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">Proposta</label>
                  <p>{viewEvent.id_Proposta || "Não informado"}</p>
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">Razão Social</label>
                  <p>{viewEvent.razao_Social || "Não informado"}</p>
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">Valor a Pagar</label>
                  <p>R$ {viewEvent.valor_pagar || "0.00"}</p>
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">Pagamento Realizado</label>
                  <p>{viewEvent.pagamento_realizado || "Pendente"}</p>
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">Conta</label>
                  <p>{viewEvent.conta || "Não informado"}</p>
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">Data Prevista</label>
                  <p>{viewEvent.start || "Não informado"}</p>
                </div>
          
                <div className="flex justify-between">
                  <button
                    type="button"
                    onClick={confirmarPagamento}
                    className="bg-green-600 text-white px-4 py-2 rounded-md"
                  >
                    Confirmar Pagamento
                  </button>
                  <button
                    type="button"
                    onClick={() => setShowView(false)}
                    className="bg-gray-600 text-white px-4 py-2 rounded-md"
                  >
                    Fechar
                  </button>
                </div>              
              </div>
            </div>
          )}
        </>
      ) : (
        <p>Carregando eventos...</p> // Mensagem de carregamento
      )}
    </div>
  );
};

export default Financeiro;
